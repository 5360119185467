import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import { AgGridReact } from 'ag-grid-react'
import axios from 'axios'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import ButtonBar, { Button } from '../../layout/ButtonBar/ButtonBar'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'
import './Job.css'
import Collapsible from '../../layout/Collapsible/Collapsible'
import UserContext from '../../Context/UserContext'

const Job = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [jobInfo, setJobInfo] = useState({
		poNumber: '',
		customer: '',
		status: { value: 'Received', label: 'Received' },
		dueDate: '',
		poDate: '', // Add poDate to the state
		shipmentAddress: '',
	})
	const [materials, setMaterials] = useState([])
	const [newMaterial, setNewMaterial] = useState({
		label: 'Search for material...',
		value: '',
	})
	const [customerOptions, setCustomerOptions] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	const [successMessage, setSuccessMessage] = useState('')
	const [gridApi, setGridApi] = useState(null)
	const [isSaving, setIsSaving] = useState(false)
	const [selectedRows, setSelectedRows] = useState([])

	const statusOptions = [
		{ value: 'Received', label: 'Received' },
		{ value: 'In Progress', label: 'In Progress' },
		{ value: 'On Hold', label: 'On Hold' },
		{ value: 'Cancelled', label: 'Cancelled' },
		{ value: 'Complete', label: 'Complete' },
		{ value: 'Closed', label: 'Closed' },
	]

	const statusStyles = {
		control: (provided, state) => ({
			...provided,
			backgroundColor: state.selectProps.value ? getStatusColor(state.selectProps.value.value) : 'grey',
			color: 'white',
		}),
		singleValue: provided => ({
			...provided,
			color: 'white',
		}),
	}

	const getStatusColor = status => {
		switch (status) {
			case 'Received':
				return 'rgb(244, 39, 255)'
			case 'In Progress':
				return 'rgb(0, 174, 157)'
			case 'On Hold':
				return 'rgb(225, 165, 0)'
			case 'Cancelled':
				return 'rgb(195, 0, 0)'
			case 'Complete':
				return 'rgb(0, 176, 21)'
			case 'Closed':
				return 'rgb(97, 97, 97)'
			default:
				return 'grey'
		}
	}

	useEffect(() => {
		if (id) {
			// Fetch job data from the database
			axios
				.get(`/api/job/${id}`)
				.then(response => {
					const job = response.data
					setJobInfo({
						poNumber: job.po_number,
						customer: { value: job.customer_id, label: job.customer.display_name }, // Set customer label
						status: { value: job.status, label: job.status },
						dueDate: job.due_date.split('T')[0], // Format date to YYYY-MM-DD
						poDate: job.po_date ? job.po_date.split('T')[0] : '' // Format date to YYYY-MM-DD
					})
					const formattedMaterials = job.materials.map(material => ({
						material: material.material,
						quantity: material.quantity,
						rate: material.rate,
						shipped: 0, // Default value, will be updated later
						remaining: material.quantity, // Default value, will be updated later
						amount: material.quantity * material.rate,
					}))
					setMaterials(formattedMaterials)
					// Fetch shipments to calculate shipped quantities
					axios
						.get(`/api/shipment/job/${id}`)
						.then(response => {
							const shipments = response.data
							const updatedMaterials = formattedMaterials.map(material => {
								const totalShipped = shipments.reduce((sum, shipment) => {
									if (shipment.is_void) return sum; // Ignore void shipments
									const shipmentMaterial = shipment.materials.find(m => m.material_id === material.material.material_id)
									return sum + (shipmentMaterial ? parseFloat(shipmentMaterial.quantity) : 0)
								}, 0)
								return {
									...material,
									shipped: totalShipped,
									remaining: material.quantity - totalShipped,
								}
							})
							setMaterials(updatedMaterials)
						})
						.catch(error => {
							console.error('Error fetching shipments:', error)
						})
				})
				.catch(error => {
					if (error.response && error.response.status === 404) {
						setErrorMessage(`Job ${id} not found in database.`)
					} else {
						console.error('Error fetching job data:', error)
					}
				})
		}
	}, [id])

	useEffect(() => {
		// Fetch customer options from the API
		axios
			.get('/api/customer')
			.then(response => {
				const customers = response.data
				const options = customers.map(c => ({
					value: c.customer_id,
					label: c.display_name,
				}))
				setCustomerOptions(options)
			})
			.catch(error => {
				console.error('Error fetching customer options:', error)
			})
	}, [])

	const handleInputChange = e => {
		const { name, value } = e.target
		setJobInfo({ ...jobInfo, [name]: value })
	}

	const handleNewMaterialChange = selectedOption => {
		setNewMaterial(selectedOption)
	}

	const handleCustomerChange = selectedOption => {
		setJobInfo({ ...jobInfo, customer: selectedOption })
	}

	const handleStatusChange = selectedOption => {
		setJobInfo({ ...jobInfo, status: selectedOption })
	}

	const addNewMaterial = () => {
		if (!newMaterial.value) return

		axios
			.get(`/api/material?id=${newMaterial.value}`)
			.then(response => {
				const material = response.data
				setMaterials([
					...materials,
					{
						material: material,
						quantity: 1, // Default quantity
						rate: material.sales_price,
						shipped: 0,
						remaining: 1,
						amount: 1 * newMaterial.rate,
					},
				])
			})
			.catch(error => {
				console.error('Error fetching material details:', error)
			})
		setNewMaterial({
			label: 'Search for material...',
			value: '',
		})
	}

	const fetchMaterialOptions = (inputValue, callback) => {
		if (inputValue.length < 2) {
			return callback([])
		}

		axios
			.get(`/api/material?is_vendable=true&search=${inputValue}`)
			.then(response => {
				const materials = response.data
				const options = materials.map(m => ({
					value: m.material_id,
					label: `[${m.sku}] ${m.name}`,
				}))
				callback(options)
			})
			.catch(error => {
				console.error('Error fetching material options:', error)
				callback([])
			})
	}

	const materialColumns = [
		{ headerName: 'SKU', field: 'material.sku', checkboxSelection: true },
		{ headerName: 'Material', field: 'material.name' },
		{ headerName: 'Quantity', field: 'quantity', editable: true },
		{ headerName: 'Rate', field: 'rate', editable: true, valueFormatter: params => `£${Number(params.value).toFixed(2)}` },
		{ headerName: 'Amount', field: 'amount', valueGetter: params => params.data.quantity * params.data.rate, valueFormatter: params => `£${Number(params.value).toFixed(2)}` },
		{ headerName: 'Inventory', field: 'material.inventory', valueGetter: params => 0, cellRenderer: InventoryRenderer },
		{ headerName: 'Shipped', field: 'shipped' }, // Updated shipped column
		{ headerName: 'Remaining', field: 'remaining', valueGetter: params => parseFloat(params.data.quantity - params.data.shipped) },
	]

	const handleSaveJob = () => {
		setIsSaving(true)
		const jobData = {
			po_number: jobInfo.poNumber,
			customer_id: jobInfo.customer.value,
			status: jobInfo.status.value,
			due_date: jobInfo.dueDate,
			po_date: jobInfo.poDate, // Include po_date in the job data
			materials: materials.map(material => ({
				material_id: material.material.material_id,
				quantity: material.quantity,
				rate: material.rate,
			})),
		}

		const request = id
			? axios.patch(`/api/job/${id}`, jobData)
			: axios.post('/api/job', jobData)

		request
			.then(response => {
				console.log('Job saved successfully:', response.data)
				setSuccessMessage('Job saved successfully.')
				setErrorMessage('')
				setIsSaving(false)
				if (!id) {
					navigate(`/job/${response.data.job_id}`)
				}
				setTimeout(() => setSuccessMessage(''), 3000) // Hide message after 3 seconds
			})
			.catch(error => {
				console.error('Error saving job:', error)
				setErrorMessage('Error saving job. Please try again.')
				setSuccessMessage('')
				setIsSaving(false)
			})
	}

	const deleteSelectedRows = () => {
		const confirmDelete = window.confirm('Are you sure you want to remove the selected rows?')
		if (!confirmDelete) return
		if (selectedRows.length > 0) {
			const updatedMaterials = materials.filter(material => !selectedRows.includes(material))
			setMaterials(updatedMaterials)
		}
	}

	const navigateToQuickAssemble = (material_id) => {
		navigate(`/quick-assemble/${material_id}`)
	}

	const onSelectionChanged = () => {
		const selectedData = gridApi.getSelectedRows()
		setSelectedRows(selectedData)
	}

	if (errorMessage) {
		return (
			<div id='Job'>
				<PageTitle title={"Error"}></PageTitle>
				<PageContent>
					<ErrorMessage message={errorMessage} />
				</PageContent>
			</div>
		)
	}

	return (
		<div id='Job'>
			<PageTitle title={id ? `Edit Job #J-${id}` : 'Create Job'}>
				<button className='job-save-button' type='button' onClick={handleSaveJob} disabled={isSaving} style={{ float: 'right' }}>
					{isSaving ? 'Saving...' : 'Save Job'}
				</button>
			</PageTitle>
			<PageContent>
				<Collapsible title='Job Details'>
					<form className='job-form'>
						<label>
							PO Number:
							<input type='text' name='poNumber' value={jobInfo.poNumber} onChange={handleInputChange} />
						</label>
						<label>
							Status:
							<Select options={statusOptions} value={jobInfo.status} onChange={handleStatusChange} placeholder='Select Status' styles={statusStyles} />
						</label>
						<label>
							Customer:
							<Select options={customerOptions} value={jobInfo.customer} onChange={handleCustomerChange} placeholder='Select Customer' />
						</label>
						<label>
							Due Date:
							<input type='date' name='dueDate' value={jobInfo.dueDate} onChange={handleInputChange} />
						</label>
						<label>
							PO Date:
							<input type='date' name='poDate' value={jobInfo.poDate} onChange={handleInputChange} /> {/* Add PO Date input */}
						</label>
					</form>
				</Collapsible>
				<Collapsible title='Materials'>
					<ButtonBar>
					<button className="Button" onClick={() => {navigateToQuickAssemble(selectedRows[0]?.material.material_id)}} disabled={(selectedRows.length !== 1) || (typeof selectedRows[0]?.material.default_bom_id === 'undefined')}>Quick Assemble</button>
					<button className="Button negative" onClick={deleteSelectedRows} disabled={selectedRows < 1}>Delete</button>
					</ButtonBar>
					<div className='ag-theme-quartz' style={{ height: 400, marginTop: 20 }}>
						<AgGridReact
							rowData={materials}
							columnDefs={materialColumns}
							pagination={false}
							rowSelection='multiple'
							onGridReady={params => setGridApi(params.api)}
							onSelectionChanged={onSelectionChanged}
						/>
					</div>
					<div className='new-material-row'>
							<AsyncSelect loadOptions={fetchMaterialOptions} value={newMaterial} onChange={handleNewMaterialChange} placeholder='Select Material' />
							<button type='button' onClick={addNewMaterial}>
								Add Material
							</button>
						</div>
				</Collapsible>
				{id &&
					<Collapsible title='Shipments'>
						<Shipments job_id={id} />
					</Collapsible>
				}
				{successMessage && <div className='success-message'>{successMessage}</div>}
			</PageContent>
		</div>
	)
}

const Shipments = ({ job_id }) => {
	const [shipments, setShipments] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const navigate = useNavigate();
	const [selectedRows, setSelectedRows] = useState([]);
	const [gridApi, setGridApi] = useState(null)

	useEffect(() => {
		axios
			.get(`/api/shipment/job/${job_id}`)
			.then(response => {
				setShipments(response.data);
			})
			.catch(error => {
				console.error('Error fetching shipments:', error);
				setErrorMessage('Error fetching shipments. Please try again.');
			});
	}, [job_id]);

	const voidShipment = async (shipment_id) => {
		const confirmVoid = window.confirm('Are you sure you want to void this shipment? This action cannot be undone.');
		if (!confirmVoid) return;

		try {
			await axios.delete(`/api/shipment/${shipment_id}`);
			const updatedShipments = shipments.map(shipment => 
				shipment.shipment_id === shipment_id ? { ...shipment, is_void: true } : shipment
			);
			setShipments(updatedShipments);
			alert('Shipment voided successfully.');
		} catch (error) {
			console.error('Error voiding shipment:', error);
			alert('Error voiding shipment. Please try again.');
		}
	};

	const shipmentColumns = [
		{ 
			headerName: 'Shipment ID', 
			field: 'shipment_id', 
			cellRenderer: params => (
				<>
					<a href={`/shipment/${params.value}`}>#S-{params.value}</a>
					{params.data.is_void && <span style={{ color: 'red' }}> (Void)</span>}
				</>
			),
			checkboxSelection: true
		},
		{ headerName: 'Shipped Date', field: 'shipped_date', valueFormatter: params => params.value.split('T')[0] },
		{ headerName: 'Carrier', field: 'carrier' },
		{ headerName: 'Tracking Code', field: 'tracking_code' },
		{ headerName: 'Status', field: 'status' },
		{ headerName: 'Notes', field: 'notes' }
	];
	
	const handleOnSelectionChanged = () => {
		const selectedRows = gridApi.getSelectedRows();
		setSelectedRows(selectedRows);
	}

	const navigateToCreateShipment = () => {
		navigate(`/create-shipment/${job_id}`);
	};

	if (errorMessage) {
		return <ErrorMessage message={errorMessage} />;
	}

	return (
		<div className='ag-theme-quartz' style={{ height: 400, marginTop: 20 }}>
			<ButtonBar>
				<Button onClick={navigateToCreateShipment}>Create Shipment</Button>
				<Button onClick={() => voidShipment(selectedRows[0].shipment_id)} className="negative" disabled={selectedRows.length !== 1}>Delete Shipment</Button>
			</ButtonBar>
			<AgGridReact
				rowData={shipments}
				columnDefs={shipmentColumns}
				pagination={false}
				rowSelection='single'
				onSelectionChanged={handleOnSelectionChanged}
				onGridReady={params => setGridApi(params.api)}
			/>
		</div>
	);
}

const InventoryRenderer = ({ value, data }) => {
	const [inventory, setInventory] = useState(null)

	useEffect(() => {
		axios
			.get(`/api/inventory?material_id=${data.material.material_id}`)
			.then(response => {
				const totalInventory = response.data.reduce((sum, item) => sum + parseFloat(item.qty_on_hand), 0)
				setInventory(totalInventory)
				console.log(response.data)
			})
			.catch(error => {
				console.error('Error fetching inventory:', error)
				setInventory('Error')
			})
	}, [data.material.material_id])

	const style = inventory < 1 ? { color: 'red' } : { color: 'green' }
	return <span style={style}>{inventory !== null ? inventory : 'Loading...'}</span>
}

export default Job

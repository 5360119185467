import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import { AgCharts } from 'ag-charts-react'
import useFetch from '../../../hooks/useFetch'
import useDate from '../../../hooks/useDate'
import { formatDate } from '../../../utils/format'
import Collapsible from '../../layout/Collapsible/Collapsible'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'
import LoadingMessage from '../../layout/LoadingMessage/LoadingMessage'
import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import ButtonBar from '../../layout/ButtonBar/ButtonBar'
import UserContext from '../../Context/UserContext'
import './Timesheets.css'

const Timesheets = () => {
	const { error, isPending, data: timesheets, reloadData: reloadTimesheets } = useFetch('/api/timesheet')
	const { error: payRunError, isPending: payRunPending, data: payRuns, reloadData: reloadPayRuns } = useFetch('/api/timesheet/payruns')
	const [selectedTs, setSelectedTs] = useState([])
	const tsAreSelected = selectedTs.length > 0
	const closedTsAreSelected = selectedTs.every(ts => ts.status === 'CLOSED')
	const [showEdit, setShowEdit] = useState(false)
	const [showNewEntry, setShowNewEntry] = useState(false)
	const [startDateTime, setStartDateTime] = useState('')
	const [endDateTime, setEndDateTime] = useState('')
	const [breakMinutes, setBreakMinutes] = useState(0)
	const { user } = useContext(UserContext)
	const [selectedPayRun, setSelectedPayRun] = useState(null)

	useEffect(() => {
		if (selectedTs.length !== 1) setShowEdit(false)
	}, [selectedTs])

	const chartData = useMemo(() => {
		if (!timesheets) return []
		const userHoursMap = timesheets.reduce((acc, ts) => {
			const clockOutTime = ts.clock_out_time ? new Date(ts.clock_out_time) : new Date()
			const breakMinutes = ts.break_minutes || 0
			const hours = (clockOutTime - new Date(ts.clock_in_time)) / (1000 * 60 * 60) - breakMinutes / 60
			if (acc[ts.user_name]) {
				acc[ts.user_name] += hours
			} else {
				acc[ts.user_name] = hours
			}
			return acc
		}, {})
		const data = Object.entries(userHoursMap).map(([user, hours]) => ({ user, hours }))
		return data
	}, [timesheets])

	const [chartOptions, setChartOptions] = useState({
		data: [],
		series: [
			{
				type: 'bar',
				xKey: 'user',
				yKey: 'hours',
				yName: 'Total Payable Hours',
			},
		],
		axes: [
			{
				type: 'category',
				position: 'bottom',
				title: { text: 'User' },
			},
			{
				type: 'number',
				position: 'left',
				title: { text: 'Hours' },
			},
		],
		legend: { enabled: false },
	})

	useEffect(() => {
		setChartOptions(prevOptions => ({
			...prevOptions,
			data: chartData, // Set the updated data
		}))
	}, [chartData])

	const handleEditClick = () => {
		setStartDateTime(selectedTs[0].clock_in_time)
		setEndDateTime(selectedTs[0].clock_out_time)
		setBreakMinutes(selectedTs[0].break_minutes)
		setShowEdit(true)
		setShowNewEntry(false)
	}

	const handleDateTimeChange = e => {
		if (!e.target.validity.valid) return
		const dt = e.target.value + ':00Z'
		if (e.target.name === 'clock_in_time') {
			setStartDateTime(dt)
		} else if (e.target.name === 'clock_out_time') {
			setEndDateTime(dt)
		}
	}

	const handleBreakMinutesChange = e => {
		setBreakMinutes(parseInt(e.target.value, 10))
	}

	const handleSubmitTsEdit = async e => {
		e.preventDefault()
		try {
			const response = await fetch('/api/timesheet/edit', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					timesheetId: selectedTs[0].timesheet_id,
					clockInTime: startDateTime,
					clockOutTime: endDateTime,
					breakMinutes: parseInt(breakMinutes, 10),
				}),
			})

			if (!response.ok) {
				const errorData = await response.json()
				alert(errorData.error || 'Failed to edit timesheet. Please try again.')
				return
			}

			alert('Timesheet edited successfully.')
			reloadTimesheets()
			handleCancelEdit()
		} catch (error) {
			console.error('Error editing timesheet:', error)
			alert('An error occurred while editing the timesheet.')
		}
	}

	const handleCancelEdit = () => {
		setShowEdit(false)
	}

	const handleNewEntryClick = () => {
		setShowNewEntry(true)
		setShowEdit(false)
	}

	const handleCancelNewEntry = () => {
		setShowNewEntry(false)
	}

	const handleDeleteClick = async () => {
		if (!selectedTs.length) {
			alert('Please select a timesheet to void.')
			return
		}

		const confirmDelete = window.confirm('Are you sure you want to void the selected timesheet?')
		if (!confirmDelete) return

		try {
			const response = await fetch(`/api/timesheet/void/${selectedTs[0].timesheet_id}`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
			})

			if (!response.ok) {
				const errorData = await response.json()
				alert(errorData.error || 'Failed to void timesheet. Please try again.')
				return
			}

			alert('Timesheet voided successfully.')
			reloadTimesheets()
		} catch (error) {
			console.error('Error voiding timesheet:', error)
			alert('An error occurred while voiding the timesheet.')
		}
	}

	const handleRunPayrollClick = async () => {
		const timesheetIds = selectedTs.map(ts => ts.timesheet_id)
		try {
			const response = await fetch('/api/timesheet/run-payroll', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ timesheetIds }),
			})

			if (!response.ok) {
				const errorData = await response.json()
				alert(errorData.error || 'Failed to run payroll. Please try again.')
				return
			}

			alert('Payroll run successfully.')
			reloadTimesheets()
			reloadPayRuns()
		} catch (error) {
			console.error('Error running payroll:', error)
			alert('An error occurred while running payroll.')
		}
	}

	const handleDeletePayRunClick = async () => {
		if (!selectedPayRun) {
			alert('Please select a PayRun to delete.')
			return
		}

		const confirmDelete = window.confirm('Are you sure you want to delete the selected PayRun?')
		if (!confirmDelete) return

		try {
			const response = await fetch(`/api/timesheet/payrun/${selectedPayRun.payrun_id}`, {
				method: 'DELETE',
				headers: { 'Content-Type': 'application/json' },
			})

			if (!response.ok) {
				const errorData = await response.json()
				alert(errorData.error || 'Failed to delete PayRun. Please try again.')
				return
			}

			alert('PayRun deleted successfully.')
			reloadPayRuns()
		} catch (error) {
			console.error('Error deleting PayRun:', error)
			alert('An error occurred while deleting the PayRun.')
		}
	}

	return (
		<div id='Timesheets'>
			<PageTitle title={"Timesheets"}></PageTitle>
			<PageContent>
				<Collapsible title='Clocking'>
					<Clocking cb={reloadTimesheets} />
				</Collapsible>
				<Collapsible title='Total Payable Hours Chart'>
					<AgCharts options={chartOptions} />
				</Collapsible>
				<Collapsible title='Timesheets'>
					{error && <ErrorMessage message={error} />}
					{isPending && <LoadingMessage />}
					<ButtonBar>
						<button className='Button' onClick={handleNewEntryClick} disabled={!user.isAdmin}>
							New Entry
						</button>
						<button className='Button' onClick={handleRunPayrollClick} disabled={!closedTsAreSelected || !tsAreSelected}>
							Run Payroll
						</button>
						<button className='Button' onClick={handleEditClick} disabled={!(selectedTs.length === 1) || !user.isAdmin}>
							Edit
						</button>
						<button className='Button negative' onClick={handleDeleteClick} disabled={!(selectedTs.length === 1) || !user.isAdmin}>
							Delete
						</button>
					</ButtonBar>
					<div className='widget-container'>
						<TimesheetEditor
							selectedTs={selectedTs[0]}
							startDateTime={startDateTime}
							endDateTime={endDateTime}
							breakMinutes={breakMinutes}
							handleDateTimeChange={handleDateTimeChange}
							handleBreakMinutesChange={handleBreakMinutesChange}
							handleSubmitTsEdit={handleSubmitTsEdit}
							handleCancelEdit={handleCancelEdit}
							className={showEdit && tsAreSelected ? 'widget show' : 'widget'}
							tsAreSelected={tsAreSelected}
						/>
						<NewTimesheetEntry handleCancelNewEntry={handleCancelNewEntry} reloadTimesheets={reloadTimesheets} className={showNewEntry ? 'widget show' : 'widget'} />
					</div>
					<TimesheetsTable data={timesheets} setSelectedTs={setSelectedTs} />
				</Collapsible>
				<Collapsible title='Pay Runs'>
					{payRunError && <ErrorMessage message={payRunError} />}
					{payRunPending && <LoadingMessage />}
					<ButtonBar>
						<button className='Button negative' onClick={handleDeletePayRunClick} disabled={!selectedPayRun || !user.isAdmin}>
							Delete PayRun
						</button>
					</ButtonBar>
					<PayRunsTable data={payRuns} setSelectedPayRun={setSelectedPayRun} />
				</Collapsible>
			</PageContent>
		</div>
	)
}

const TimesheetEditor = ({ selectedTs, startDateTime, endDateTime, breakMinutes, handleDateTimeChange, handleBreakMinutesChange, handleSubmitTsEdit, handleCancelEdit, className, tsAreSelected }) => {
	if (!tsAreSelected) return null

	return (
		<form className={className} onSubmit={handleSubmitTsEdit}>
			<fieldset>
				<legend>
					Editing Timesheet #{selectedTs.timesheet_id} for {selectedTs.user_name}
				</legend>
				<p>Created at: {formatDate(selectedTs.created_date)}</p>
				<p>
					Clock in: {formatDate(selectedTs.real_clock_in_time)} - Clock out: {formatDate(selectedTs.real_clock_out_time)}
				</p>
				<div className='form-group'>
					<label htmlFor='clock_in_time'>Start Time</label>
					<input type='datetime-local' id='clock_in_time' name='clock_in_time' value={startDateTime ? startDateTime.substring(0, 16) : ''} onChange={handleDateTimeChange} />
				</div>
				{selectedTs.clock_out_time && (
					<div className='form-group'>
						<label htmlFor='clock_out_time'>End Time</label>
						<input type='datetime-local' id='clock_out_time' name='clock_out_time' value={endDateTime ? endDateTime.substring(0, 16) : ''} onChange={handleDateTimeChange} />
					</div>
				)}
				<div className='form-group'>
					<label htmlFor='break_minutes'>Break Minutes</label>
					<input type='number' id='break_minutes' name='break_minutes' value={breakMinutes !== null ? breakMinutes : null} placeholder={breakMinutes == null ? 'Auto' : ''} onChange={handleBreakMinutesChange} />
				</div>
				<button type='submit'>Save</button>
				<button type='button' onClick={handleCancelEdit}>
					Cancel
				</button>
			</fieldset>
		</form>
	)
}

const NewTimesheetEntry = ({ handleCancelNewEntry, reloadTimesheets, className }) => {
	const [userId, setUserId] = useState('')
	const [clockInTime, setClockInTime] = useState(new Date().toISOString().substring(0, 16))
	const [clockOutTime, setClockOutTime] = useState(new Date().toISOString().substring(0, 16))

	const handleUserIdChange = e => setUserId(e.target.value)
	const handleClockInTimeChange = e => setClockInTime(e.target.value)
	const handleClockOutTimeChange = e => setClockOutTime(e.target.value)

	const handleSubmitNewEntry = async e => {
		e.preventDefault()
		try {
			const response = await fetch('/api/timesheet/create', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					userId,
					clockInTime,
					clockOutTime,
					realClockInTime: new Date().toISOString(),
					realClockOutTime: new Date().toISOString(),
				}),
			})

			if (!response.ok) {
				const errorData = await response.json()
				alert(errorData.error || 'Failed to create new timesheet entry. Please try again.')
				return
			}

			alert('New timesheet entry created successfully.')
			reloadTimesheets()
			handleCancelNewEntry()
		} catch (error) {
			console.error('Error creating new timesheet entry:', error)
			alert('An error occurred while creating the new entry.')
		}
	}

	return (
		<form className={className} onSubmit={handleSubmitNewEntry}>
			<fieldset>
				<legend>Create New Timesheet Entry</legend>
				<div className='form-group'>
					<label htmlFor='user_id'>User ID</label>
					<input type='text' id='user_id' name='user_id' value={userId} onChange={handleUserIdChange} required />
				</div>
				<div className='form-group'>
					<label htmlFor='clock_in_time'>Clock In Time</label>
					<input type='datetime-local' id='clock_in_time' name='clock_in_time' value={clockInTime} onChange={handleClockInTimeChange} required />
				</div>
				<div className='form-group'>
					<label htmlFor='clock_out_time'>Clock Out Time</label>
					<input type='datetime-local' id='clock_out_time' name='clock_out_time' value={clockOutTime} onChange={handleClockOutTimeChange} required />
				</div>
				<button type='submit'>Create</button>
				<button type='button' onClick={handleCancelNewEntry}>
					Cancel
				</button>
			</fieldset>
		</form>
	)
}

const TimesheetsTable = ({ data, setSelectedTs }) => {
	const [gridApi, setGridApi] = useState(null)

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	const defaultColDef = useMemo(
		() => ({
			filter: true,
		}),
		[]
	)

	const colDefs = useMemo(
		() => [
			{ headerName: 'Timesheet ID', field: 'timesheet_id', checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true },
			{ headerName: 'User ID', field: 'user_id' },
			{ headerName: 'Name', field: 'user_name' },
			{
				headerName: 'Clock In Date/Time',
				field: 'clock_in_time',
				cellRenderer: ({ value }) => formatDate(value),
			},
			{
				headerName: 'Clock Out Date/Time',
				field: 'clock_out_time',
				cellRenderer: ({ value }) => (value ? formatDate(value) : <span className='status-button yellow'>Pending</span>),
			},
			{
				headerName: 'Duration',
				cellRenderer: params => {
					const { clock_in_time, clock_out_time } = params.data
					if (!clock_out_time) return <span className='status-button yellow'>Pending</span>
					const start = new Date(clock_in_time)
					const end = new Date(clock_out_time)
					const diffMs = end - start
					const hours = Math.floor(diffMs / (1000 * 60 * 60))
					const minutes = Math.floor((diffMs / (1000 * 60)) % 60)
					return `${hours}h ${minutes}m`
				},
			},
			{
				headerName: 'Breaks',
				field: 'break_minutes',
				cellRenderer: params => {
					const { break_minutes } = params.data
					if (break_minutes === null) return <span className='status-button yellow'>Pending</span>
					const hours = Math.floor(break_minutes / 60)
					const minutes = break_minutes % 60
					return `${hours}h ${minutes}m`
				},
			},
			{
				headerName: 'Payable Hours',
				cellRenderer: params => {
					const { clock_in_time, clock_out_time, break_minutes } = params.data
					if (!clock_out_time) return <span className='status-button yellow'>Pending</span>
					const start = new Date(clock_in_time)
					const end = new Date(clock_out_time)
					const diffMs = end - start
					let hours = diffMs / (1000 * 60 * 60)
					if (break_minutes !== null) {
						hours -= break_minutes / 60
					}
					return hours.toFixed(2)
				},
			},
			{
				headerName: 'Status',
				field: 'status',
				cellRenderer: params => {
					const { status } = params.data
					if (status === 'CLOSED') return <span className='status-button grey'>Closed</span>
					if (status === 'OPEN') return <span className='status-button yellow'>Open</span>
					if (status === 'PAID') return <span className='status-button green'>Paid</span>
					return <span className='status-button red'>{status}</span>
				},
			},
		],
		[]
	)

	const autoSizeStrategy = {
		type: 'fitGridWidth',
		defaultMinWidth: 150,
		columnLimits: [
			{ colId: 'timesheet_id', minWidth: 150 },
			{ colId: 'clock_in_time', minWidth: 300 },
			{ colId: 'clock_out_time', minWidth: 300 },
			{ colId: 'status', minWidth: 100 },
			{ colId: 'user_id', minWidth: 100 },
			{ colId: 'user_name', minWidth: 200 },
		],
	}

	const onSelectionChanged = () => {
		const selectedData = gridApi.getSelectedRows()
		setSelectedTs(selectedData)
	}

	return (
		<div className='ag-theme-quartz' style={{ height: 400 }}>
			<AgGridReact
				rowData={data}
				columnDefs={colDefs}
				pagination={false}
				paginationAutoPageSize={true}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				rowSelection='multiple'
				autoSizeStrategy={autoSizeStrategy}
				onSelectionChanged={onSelectionChanged}
			/>
		</div>
	)
}

const Clocking = ({ cb }) => {
	const [staffId, setStaffId] = useState('')
	const [message, setMessage] = useState('')
	const { date, time } = useDate()

	const handleStaffIdChange = e => setStaffId(e.target.value)

	const handleClockInClick = async () => {
		if (!staffId) {
			setMessage('Please enter a Staff ID')
			return
		}

		try {
			const response = await fetch('/api/timesheet/clock-in', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ userId: staffId }),
			})

			if (!response.ok) {
				const errorData = await response.json()
				setMessage(errorData.error || 'Failed to clock in. Please try again.')
				return
			}

			const data = await response.json()
			setMessage(`Timesheet created successfully for user: ${data.userName}`)
		} catch (error) {
			console.error('Error clocking in:', error)
			setMessage('An error occurred while clocking in.')
		}

		setStaffId('')
		cb && cb()
	}

	const handleClockOutClick = async () => {
		if (!staffId) {
			setMessage('Please enter a Staff ID')
			return
		}

		try {
			const response = await fetch('/api/timesheet/clock-out', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ userId: staffId }),
			})

			if (!response.ok) {
				const errorData = await response.json()
				setMessage(errorData.error || 'Failed to clock out. Please try again.')
				return
			}

			const data = await response.json()
			setMessage(`Timesheet clocked out successfully for user: ${data.userName}`)
		} catch (error) {
			console.error('Error clocking out:', error)
			setMessage('An error occurred while clocking out.')
		}

		setStaffId('')
		cb && cb()
	}

	return (
		<div className='Clocking'>
			<p className='clocking-title'>Clocking</p>
			<p>Current Time</p>
			<p className='time'>
				{date} - {time}
			</p>
			<p>{message}</p>
			<label>Enter staff ID:</label>
			<input type='number' value={staffId} onChange={handleStaffIdChange} />
			<button onClick={handleClockInClick}>Clock in</button>
			<button className='negative' onClick={handleClockOutClick}>
				Clock out
			</button>
		</div>
	)
}

const PayRunsTable = ({ data, setSelectedPayRun }) => {
	const [gridApi, setGridApi] = useState(null)
	const navigate = useNavigate()

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	const handleViewPayRunClick = payRunId => {
		navigate(`/payrun/${payRunId}`)
	}

	const defaultColDef = useMemo(
		() => ({
			filter: true,
		}),
		[]
	)

	const colDefs = useMemo(
		() => [
			{
				headerName: 'Pay Run ID',
				field: 'payrun_id',
				checkboxSelection: true,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				cellRenderer: params => <a href={`/payrun/${params.value}`}>#{params.value}</a>,
			},
			{ headerName: 'User ID', field: 'payee_user_id' },
			{ headerName: 'Name', field: 'payee.name' },
			{
				headerName: 'For Month',
				valueGetter: params => {
					const createdAt = new Date(params.data.created_at)
					return createdAt.toLocaleString('default', { month: 'long', year: 'numeric' })
				},
			},
			{ headerName: 'Date Created', field: 'created_at', cellRenderer: ({ value }) => formatDate(value) },
			{
				headerName: 'Total Hours',
				valueGetter: params => {
					const totalHours = params.data.decimal_hours
					const hours = Math.floor(totalHours)
					const minutes = Math.round((totalHours - hours) * 60)
					return `${hours}h ${minutes}m`
				},
			},
			{
				headerName: 'Total Breaks',
				valueGetter: params => {
					const totalBreaks = params.data.decimal_breaks
					const hours = Math.floor(totalBreaks)
					const minutes = Math.round((totalBreaks - hours) * 60)
					return `${hours}h ${minutes}m`
				},
			},
			{
				headerName: 'Total Payable Hours',
				field: 'decimal_hours_payable',
			},
		],
		[]
	)

	const autoSizeStrategy = {
		type: 'fitGridWidth',
		defaultMinWidth: 150,
		columnLimits: [
			{ colId: 'payee_user_id', minWidth: 150 },
			{ colId: 'created_at', minWidth: 300 },
			{ colId: 'decimal_hours', minWidth: 150 },
			{ colId: 'decimal_breaks', minWidth: 150 },
			{ colId: 'decimal_hours_payable', minWidth: 150 },
		],
	}

	const onSelectionChanged = () => {
		const selectedData = gridApi.getSelectedRows()
		setSelectedPayRun(selectedData[0])
	}

	return (
		<div className='ag-theme-quartz' style={{ height: 400 }}>
			<AgGridReact
				rowData={data}
				columnDefs={colDefs}
				pagination={false}
				paginationAutoPageSize={true}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				rowSelection='single'
				autoSizeStrategy={autoSizeStrategy}
				onSelectionChanged={onSelectionChanged}
			/>
		</div>
	)
}

export default Timesheets
export { Clocking }

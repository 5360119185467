import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import PageContent from '../../layout/PageContent';
import PageTitle from '../../layout/PageTitle';
import { AgGridReact } from 'ag-grid-react';
import './Jobs.css';
import '../../layout/ButtonBar/ButtonBar.css'; // Import ButtonBar CSS
import ButtonBar from '../../layout/ButtonBar/ButtonBar'; // Import ButtonBar component
import axios from 'axios';

const Jobs = () => {
	const [gridApi, setGridApi] = useState(null);
	const [jobData, setJobData] = useState([]);
	const [searchTerms, setSearchTerms] = useState();
	const [selectedJobs, setSelectedJobs] = useState([])
	const navigate = useNavigate();

	const fetchJobData = async () => {
		try {
			const response = await axios.get('/api/job');
			console.log('response:', response);
			const formattedData = response.data.map(job => ({
				job_id: job.job_id, // Add job_id to the data
				poDate: new Date(job.po_date).toISOString().split('T')[0],
				poNumber: job.po_number,
				customer: job.customer, // Use the customer's display name
				status: job.status,
				dueDate: new Date(job.due_date).toISOString().split('T')[0],
				materials: job.materials || [], // Add materials to the data, default to empty array if undefined
			}));
			console.log('formattedData:', formattedData);
			setJobData(formattedData);
		} catch (error) {
			console.error('Error fetching job data:', error);
		}
	};

	useEffect(() => {
		fetchJobData();
	}, []);

	const onGridReady = useCallback(params => {
		setGridApi(params.api);
	}, []);

	const deleteSelectedJob = async () => {
		if (selectedJobs.length !== 1) {
			alert('Please select one job to delete.');
			return;
		}
		const confirmDelete = window.confirm('Are you sure you want to void the selected job?')
		if (!confirmDelete) return
		const selectedJobId = selectedJobs[0].job_id;
		try {
			await axios.delete(`/api/job/${selectedJobId}`);
			fetchJobData(); // Refresh the job data
		} catch (error) {
			console.error('Error deleting job:', error);
		}
	};

	const gridOptions = {
		rowHeight: 60,
	};

	const defaultColDef = useMemo(() => ({
		filter: true,
	}), []);

	const statusRenderer = ({ value }) => {
		const statusClass = value.toLowerCase().replace(/ /g, '-');
		return <span className={`status-button ${statusClass}`}>{value}</span>;
	};

	const dueDateRenderer = ({ value, data }) => {
		const dueDate = new Date(value);
		const today = new Date();
		const diffTime = dueDate - today;
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		const isStatusExcluded = ['complete', 'closed', 'cancelled'].includes(data.status.toLowerCase());
		let dueStatus = '';

		if (!isStatusExcluded) {
			if (diffDays < 0) {
				dueStatus = 'overdue';
			} else if (diffDays <= 7) {
				dueStatus = 'due-soon';
			}
		}

		return (
			<div>
				<span>{value}</span>
				{dueStatus && <span className={`status-button ${dueStatus}`}>{dueStatus.replace('-', ' ')}</span>}
			</div>
		);
	};

	const jobIdRenderer = ({ value }) => {
		return <Link to={`/job/${value}`}>#J-{value}</Link>; // Format Job ID and wrap in a link
	};

	const materialsRenderer = ({ value }) => {
		// Display a summary of materials in the cell
		const summary = value.slice(0, 2).map(material => (
			<div key={material.material.sku}>
				{parseFloat(material.quantity)} x [{material.material.sku}] {material.material.name}
			</div>
		));
		const additionalLines = value.length > 2 ? `+ ${value.length - 2} lines` : '';
		return (
			<div className="materials-cell">
				{summary}
				{additionalLines && <div>{additionalLines}</div>}
			</div>
		);
	};

	const onSelectionChanged = () => {
		const selectedData = gridApi.getSelectedRows()
		setSelectedJobs(selectedData)
	}

	const [colDefs, setColDefs] = useState([
		{ headerName: 'Job ID', field: 'job_id', cellRenderer: jobIdRenderer, checkboxSelection: true },
		{ headerName: 'PO Date', field: 'poDate' },
		{ headerName: 'PO Number', field: 'poNumber' },
		{ headerName: 'Customer', field: 'customer', minWidth: 200 },
		{ headerName: 'Status', field: 'status', cellRenderer: statusRenderer, minWidth: 150 },
		{ headerName: 'Due Date', field: 'dueDate', cellRenderer: dueDateRenderer, minWidth: 200 },	
		{ headerName: 'Materials', field: 'materials', cellRenderer: materialsRenderer, autoHeight: true, minWidth: 300 },
	]);

	return (
		<div id='Jobs'>
			<PageTitle title={"Jobs"}></PageTitle>
			<PageContent>
				<ButtonBar>
					<Link to='/job' className='Button'>Create New Job</Link>
					<button onClick={deleteSelectedJob} className='Button negative' disabled={selectedJobs.length !== 1}>Delete</button> {/* Add delete button */}
				</ButtonBar>
				<div className='ag-theme-quartz' style={{ height: 600 }}>
					<input type='text' value={searchTerms} onChange={e => setSearchTerms(e.target.value)} placeholder='Search..' />
					<AgGridReact
						rowData={jobData}
						columnDefs={colDefs}
						pagination={true}
						defaultColDef={defaultColDef}
						onGridReady={onGridReady}
						autoSizeStrategy={{ type: 'fitGridWidth', defaultMinWidth: 150 }}
						quickFilterText={searchTerms}
						gridOptions={gridOptions}
						onSelectionChanged={onSelectionChanged}
					/>
				</div>
			</PageContent>
		</div>
	);
}

export default Jobs;


import { useParams } from 'react-router-dom'
import useFetch from '../../../hooks/useFetch'
import { formatDate } from '../../../utils/format'
import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'
import LoadingMessage from '../../layout/LoadingMessage/LoadingMessage'
import { AgGridReact } from 'ag-grid-react'
import './PayRunDetails.css'

const PayRunDetails = () => {
	const { payRunId } = useParams()
	const { error, isPending, data: payRun } = useFetch(`/api/timesheet/payrun/${payRunId}`)

	const colDefs = [
		{ headerName: 'Timesheet ID', field: 'timesheet_id' },
		{ headerName: 'User ID', field: 'user_id' },
		{ headerName: 'Name', field: 'user_name' },
		{
			headerName: 'Duration',
			cellRenderer: params => {
				const { clock_in_time, clock_out_time } = params.data
				if (!clock_out_time) return <span className='status-button yellow'>Pending</span>
				const start = new Date(clock_in_time)
				const end = new Date(clock_out_time)
				const diffMs = end - start
				const hours = Math.floor(diffMs / (1000 * 60 * 60))
				const minutes = Math.floor((diffMs / (1000 * 60)) % 60)
				return `${hours}h ${minutes}m`
			},
		},
		{
			headerName: 'Breaks',
			cellRenderer: params => {
				const { break_minutes } = params.data
				if (break_minutes === null) return <span className='status-button yellow'>Pending</span>
				const hours = Math.floor(break_minutes / 60)
				const minutes = break_minutes % 60
				return `${hours}h ${minutes}m`
			},
		},
		{
			headerName: 'Payable Hours',
			cellRenderer: params => {
				const { clock_in_time, clock_out_time, break_minutes } = params.data
				if (!clock_out_time) return <span className='status-button yellow'>Pending</span>
				const start = new Date(clock_in_time)
				const end = new Date(clock_out_time)
				const diffMs = end - start
				let hours = diffMs / (1000 * 60 * 60)
				if (break_minutes !== null) {
					hours -= break_minutes / 60
				}
				return hours.toFixed(2)
			},
		},
		{
			headerName: 'Status',
			field: 'status',
			cellRenderer: params => {
				const { status } = params.data
				if (status === 'CLOSED') return <span className='status-button grey'>Closed</span>
				if (status === 'OPEN') return <span className='status-button yellow'>Open</span>
				if (status === 'PAID') return <span className='status-button green'>Paid</span>
				return <span className='status-button red'>{status}</span>
			},
		},
		{ headerName: 'Clock In Date/Time', field: 'clock_in_time', cellRenderer: ({ value }) => formatDate(value) },
		{ headerName: 'Clock Out Date/Time', field: 'clock_out_time', cellRenderer: ({ value }) => (value ? formatDate(value) : <span className='status-button yellow'>Pending</span>) },
	]

	const handlePrint = () => {
		const printContent = document.getElementById('printable-content').innerHTML
		const originalContent = document.body.innerHTML
		const originalTitle = document.title
		const date = new Date().toISOString().split('T')[0]
		const fileName = `${date}-payrun-${payRun.payrun_id}-user-${payRun.payee_user_id}-${payRun.payee.name}`
		document.title = fileName
		document.body.innerHTML = printContent
		window.print()
		document.body.innerHTML = originalContent
		document.title = originalTitle
		window.location.reload()
	}

	const formatDate = dateStr => {
		const date = new Date(dateStr)
		const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }
		return date.toLocaleDateString(undefined, options)
	}

	const getWeekday = dateStr => {
		const date = new Date(dateStr)
		return date.toLocaleDateString(undefined, { weekday: 'long' })
	}

	return (
		<div id='PayRunDetails'>
			<PageTitle title={"Pay Run Details"}></PageTitle>
			<PageContent>
				{error && <ErrorMessage message={error} />}
				{isPending && <LoadingMessage />}
				{payRun && (
					<>
						<div id='printable-content'>
							<div className='payrun-details'>
								<p><strong>Pay Run ID:</strong> {payRun.payrun_id}</p>
								<p><strong>Payee User ID:</strong> {payRun.payee_user_id}</p>
								<p><strong>Payee Name:</strong> {payRun.payee.name}</p>
								<p><strong>Total Hours:</strong> {payRun.decimal_hours}</p>
								<p><strong>Total Breaks:</strong> {payRun.decimal_breaks}</p>
								<p><strong>Total Payable Hours:</strong> {payRun.decimal_hours_payable}</p>
								<p><strong>Date Created:</strong> {formatDate(payRun.created_at)}</p>
							</div>
							<div className='ag-theme-quartz' style={{ height: 400 }}>
								<AgGridReact
									rowData={payRun.timesheetEntries}
									columnDefs={colDefs}
									pagination={false}
									paginationAutoPageSize={true}
									defaultColDef={{ filter: true }}
									autoSizeStrategy={{
										type: 'fitGridWidth',
										defaultMinWidth: 150,
										columnLimits: [
											{ colId: 'timesheet_id', minWidth: 150 },
											{ colId: 'clock_in_time', minWidth: 300 },
											{ colId: 'clock_out_time', minWidth: 300 },
											{ colId: 'status', minWidth: 100 },
											{ colId: 'user_id', minWidth: 100 },
											{ colId: 'user_name', minWidth: 200 },
										],
									}}
								/>
							</div>
							<div className='print-table'>
								<h1>Timesheet</h1>
								<table>
									<thead>
										<tr>
											<th>Weekday</th>
											<th>Duration</th>
											<th>Breaks</th>
											<th>Payable Hours</th>
											<th>Clock In Date/Time</th>
											<th>Clock Out Date/Time</th>
										</tr>
									</thead>
									<tbody>
										{payRun.timesheetEntries.map(entry => (
											<tr key={entry.timesheet_id}>
												<td>{getWeekday(entry.clock_in_time)}</td>
												<td>
													{entry.clock_out_time
														? (() => {
																const start = new Date(entry.clock_in_time)
																const end = new Date(entry.clock_out_time)
																const diffMs = end - start
																const hours = Math.floor(diffMs / (1000 * 60 * 60))
																const minutes = Math.floor((diffMs / (1000 * 60)) % 60)
																return `${hours}h ${minutes}m`
														  })()
														: 'Pending'}
												</td>
												<td>
													{entry.break_minutes !== null
														? (() => {
																const hours = Math.floor(entry.break_minutes / 60)
																const minutes = entry.break_minutes % 60
																return `${hours}h ${minutes}m`
														  })()
														: 'Pending'}
												</td>
												<td>
													{entry.clock_out_time
														? (() => {
																const start = new Date(entry.clock_in_time)
																const end = new Date(entry.clock_out_time)
																const diffMs = end - start
																let hours = diffMs / (1000 * 60 * 60)
																if (entry.break_minutes !== null) {
																	hours -= entry.break_minutes / 60
																}
																return hours.toFixed(2)
														  })()
														: 'Pending'}
												</td>
												<td>{formatDate(entry.clock_in_time)}</td>
												<td>{entry.clock_out_time ? formatDate(entry.clock_out_time) : 'Pending'}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
						<button onClick={handlePrint}>Print</button>
					</>
				)}
			</PageContent>
		</div>
	)
}

export default PayRunDetails

function PageTitle({title, children}) {
	return (
		<div id='PageTitle'>
			<h1>{title}</h1>
			{children}
		</div>
	)
}

export default PageTitle

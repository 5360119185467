import PageTitle from "../../layout/PageTitle"
import { AgGridReact } from "ag-grid-react" // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css" // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css" // Optional Theme applied to the Data Grid
import { useMemo, useState, useCallback, useEffect } from "react"
import { ButtonBar } from "../../layout/ButtonBar/ButtonBar"
import PageContent from "../../layout/PageContent"
import useFetch from "../../../hooks/useFetch"
import axios from "axios"
import "./Users.css"
import LoadingMessage from "../../layout/LoadingMessage/LoadingMessage"
import ErrorMessage from "../../layout/ErrorMessage/ErrorMessage"

const Users = () => {
	const {
		error,
		isPending,
		data: users,
		reloadData: reloadUserTable,
	} = useFetch("/api/user/all")

	// retrieve the selected rows from the grid
	const [selectedUsers, setSelectedUsers] = useState([])
	// boolean for if users are selected
	const usersAreSelected = selectedUsers.length > 0
	// handle when the delete selected users button is pressed
	const handleArchiveSelectedUsers = () => {
		// err here
		let userIds = selectedUsers.map(({ user_id }) => user_id)
		axios
			.post("/api/user/archive", userIds)
			.then(res => {
				console.log(res)
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}

	// toggle new user form
	const [showNewUserForm, setShowNewUserForm] = useState(false)
	const toggleNewUserForm = () => {
		setShowNewUserForm(!showNewUserForm)
	}

	// toggle set password form
	const [showPasswordForm, setShowPasswordForm] = useState(false)
	const togglePasswordForm = () => {
		setShowPasswordForm(!showPasswordForm)
	}
	useEffect(() => {
		if (selectedUsers.length !== 1) return setShowPasswordForm(false)
	}, [selectedUsers])

	return (
		<div id='Users'>
			<PageTitle title={"Users"}></PageTitle>
			<PageContent>
				<ButtonBar>
					<button className='Button' onClick={toggleNewUserForm}>
						New User
					</button>
					<button
						className='Button'
						onClick={handleArchiveSelectedUsers}
						disabled={!usersAreSelected}>
						Archive User(s)
					</button>
					<button
						className='Button'
						onClick={togglePasswordForm}
						disabled={!(selectedUsers.length === 1)}>
						Set password
					</button>
				</ButtonBar>
				{showNewUserForm && (
					<NewUserForm reloadUserTable={reloadUserTable} />
				)}
				{showPasswordForm && (
					<PasswordForm
						togglePasswordForm={togglePasswordForm}
						selectedUser={selectedUsers[0]}
					/>
				)}
				{error && <ErrorMessage message={error} />}
				{isPending && <LoadingMessage />}
				{users && (
					<UsersTable
						data={users}
						setSelectedUsers={setSelectedUsers}
					/>
				)}
			</PageContent>
		</div>
	)
}

const UsersTable = props => {
	// Row Data: The data to be displayed.
	const rowData = props.data
	console.log(props.data)
	const [gridApi, setGridApi] = useState(null)

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	// Apply settings across all columns
	const defaultColDef = useMemo(() => ({
		filter: true,
	}))

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{
			headerName: "Id",
			field: "userId",
			checkboxSelection: true,
			headerCheckboxSelection: true,
		},
		{ headerName: "Name", field: "name" },
		{ headerName: "Email", field: "email" },
		{
			headerName: "Admin",
			field: "isAdmin",
			type: "boolean",
			editable: false,
			cellRendererParams: { disabled: true },
		},
		{
			headerName: "Password Set",
			field: "hasPassword",
			type: "boolean",
			editable: false,
			cellRendererParams: { disabled: true },
		},
		{
			headerName: "Archived",
			field: "isArchived",
			type: "boolean",
			editable: false,
			cellRendererParams: { disabled: true },
		},
	])

	const onSelectionChanged = () => {
		const selectedData = gridApi.getSelectedRows()
		props.setSelectedUsers(selectedData)
	}

	const rowSelection = "multiple"

	return (
		<div className='ag-theme-quartz' style={{ height: 400 }}>
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				pagination={true}
				defaultColDef={defaultColDef}
				rowSelection={rowSelection}
				onSelectionChanged={onSelectionChanged}
				onGridReady={onGridReady}
			/>
		</div>
	)
}

const NewUserForm = props => {
	const [inputs, setInputs] = useState({})

	const handleChange = event => {
		const name = event.target.name
		const value = event.target.value
		setInputs(values => ({ ...values, [name]: value }))
	}

	const handleSubmit = async event => {
		event.preventDefault()
		axios
			.post("/api/user/add", inputs)
			.then(res => {
				console.log(res)
				alert("New user created.")
				props.reloadUserTable()
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}
	return (
		<div id='NewUserForm'>
			<form onSubmit={handleSubmit}>
				<label htmlFor='name'>Name</label>
				<input
					name='name'
					type='text'
					value={inputs.name || ""}
					onChange={handleChange}
				/>
				<label htmlFor='email'>Email</label>
				<input
					name='email'
					type='email'
					value={inputs.email || ""}
					onChange={handleChange}
				/>
				<input type='submit' />
			</form>
		</div>
	)
}

const PasswordForm = ({ selectedUser, togglePasswordForm }) => {
	const [inputs, setInputs] = useState({})

	const handleChange = event => {
		const name = event.target.name
		const value = event.target.value
		setInputs(values => ({ ...values, [name]: value }))
	}

	const handleSubmit = async event => {
		event.preventDefault()
		if (!(inputs.password === inputs.password2))
			return alert("Passwords do not match.")
		axios
			.post("/api/auth/password", {
				userId: selectedUser.userId,
				password: inputs.password,
			})
			.then(res => {
				console.log(res)
				alert("Password set")
				togglePasswordForm()
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}
	if (!selectedUser) return <ErrorMessage message={"No user selected."} />
	return (
		<div id='PasswordForm'>
			<form onSubmit={handleSubmit}>
				<p>
					Setting new password for {selectedUser.name} (
					{selectedUser.userId})
				</p>
				<label htmlFor='name'>Enter password</label>
				<input
					name='password'
					type='password'
					value={inputs.password || ""}
					onChange={handleChange}
				/>
				<label htmlFor='password2'>Confirm password</label>
				<input
					name='password2'
					type='password'
					value={inputs.password2 || ""}
					onChange={handleChange}
				/>
				<input type='submit' />
			</form>
		</div>
	)
}

export default Users

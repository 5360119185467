import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import axios from 'axios'
import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import ButtonBar, { Button } from '../../layout/ButtonBar/ButtonBar'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'
import './CreateShipment.css'
import UserContext from '../../Context/UserContext'

const CreateShipment = () => {
	const { job_id } = useParams()
	const navigate = useNavigate()
	const [jobDetails, setJobDetails] = useState(null)
	const [shipments, setShipments] = useState([])
	const [materials, setMaterials] = useState([])
	const [shipmentInfo, setShipmentInfo] = useState({
		shipment_address: '',
		carrier: '',
		tracking_code: '',
		pallet_count: 0,
		parcel_count: 0,
		notes: '',
		shipped_date: '',
		delivery_date: '',
	})
	const [errorMessage, setErrorMessage] = useState('')
	const [isSaving, setIsSaving] = useState(false)
	const { user } = useContext(UserContext)

	useEffect(() => {
		// Fetch job details
		axios
			.get(`/api/job/${job_id}`)
			.then(response => {
				setJobDetails(response.data)
				const formattedMaterials = response.data.materials.map(material => ({
					material_id: material.material_id,
					sku: material.material.sku,
					name: material.material.name,
					total_quantity: material.quantity,
					shipped_quantity: 0, // Default value, will be updated later
					remaining_quantity: material.quantity, // Default value, will be updated later
					quantity_to_ship: 0, // Default value, editable by user
					unit_rate: material.rate,
					total_amount: 0, // Default value, will be calculated later
				}))
				setMaterials(formattedMaterials)
				setShipmentInfo(prevState => ({
					...prevState,
					shipment_address: response.data.shipment_address,
					shipped_date: new Date().toISOString().split('T')[0], // Default to today's date
				}))
			})
			.catch(error => {
				console.error('Error fetching job details:', error)
				setErrorMessage('Error fetching job details. Please try again.')
			})

		// Fetch existing shipments
		axios
			.get(`/api/shipment/job/${job_id}`)
			.then(response => {
				setShipments(response.data)
			})
			.catch(error => {
				console.error('Error fetching shipments:', error)
				setErrorMessage('Error fetching shipments. Please try again.')
			})
	}, [job_id])

	useEffect(() => {
		// Calculate total shipped and remaining quantities
		const updatedMaterials = materials.map(material => {
			const totalShipped = shipments.reduce((sum, shipment) => {
				if (shipment.is_void) return sum; // Ignore void shipments
				const shipmentMaterial = shipment.materials.find(m => m.material_id === material.material_id)
				return sum + (shipmentMaterial ? parseFloat(shipmentMaterial.quantity) : 0)
			}, 0)
			return {
				...material,
				shipped_quantity: totalShipped,
				remaining_quantity: material.total_quantity - totalShipped,
				total_amount: material.quantity_to_ship * material.unit_rate,
			}
		})
		setMaterials(updatedMaterials)
	}, [shipments]) // Only run when shipments change

	const handleQuantityChange = (material_id, quantity) => {
		const updatedMaterials = materials.map(material => {
			if (material.material_id === material_id) {
				const newQuantityToShip = Math.max(0, quantity)
				return {
					...material,
					quantity_to_ship: newQuantityToShip,
					total_amount: newQuantityToShip * material.unit_rate,
				}
			}
			return material
		})
		setMaterials(updatedMaterials)
	}

	const handleInputChange = e => {
		const { name, value } = e.target
		setShipmentInfo({ ...shipmentInfo, [name]: value })
	}

	const handleSaveShipment = () => {
		const overQuantityMaterials = materials.filter(material => material.quantity_to_ship > material.remaining_quantity)
		if (overQuantityMaterials.length > 0) {
			const confirmMessage = 'Some materials have quantities to ship that exceed the remaining quantities. Do you want to proceed?'
			if (!window.confirm(confirmMessage)) {
				return
			}
		}

		setIsSaving(true)
		const shipmentData = {
            location_id: user.activeLocation.id,
			job_id: job_id,
			shipment_address: shipmentInfo.shipment_address,
			carrier: shipmentInfo.carrier,
			tracking_code: shipmentInfo.tracking_code,
			pallet_count: shipmentInfo.pallet_count,
			parcel_count: shipmentInfo.parcel_count,
			notes: shipmentInfo.notes,
			shipped_date: shipmentInfo.shipped_date,
			delivery_date: shipmentInfo.delivery_date,
			status: 'Pending', // Default status
			materials: materials
				.filter(material => material.quantity_to_ship > 0)
				.map(material => ({
					material_id: material.material_id,
					material_name: material.name,
					quantity: material.quantity_to_ship,
					unit_rate: material.unit_rate,
					total_amount: material.total_amount,
				})),
		}
		console.log('Creating shipment:', shipmentData)

		axios
			.post('/api/shipment', shipmentData)
			.then(response => {
				console.log('Shipment created successfully:', response.data)
				setIsSaving(false)
				navigate(`/job/${job_id}`)
			})
			.catch(error => {
				console.error('Error creating shipment:', error)
				setErrorMessage('Error creating shipment. Please try again.')
				setIsSaving(false)
			})
	}

	const handleAutoFillQuantities = () => {
		const updatedMaterials = materials.map(material => ({
			...material,
			quantity_to_ship: material.remaining_quantity,
			total_amount: material.remaining_quantity * material.unit_rate,
		}))
		setMaterials(updatedMaterials)
	}

	const InventoryRenderer = ({ value, data }) => {
		const [inventory, setInventory] = useState(null)
	
		useEffect(() => {
			axios
				.get(`/api/inventory?material_id=${data.material_id}`)
				.then(response => {
					const totalInventory = response.data.reduce((sum, item) => sum + parseFloat(item.qty_on_hand), 0)
					setInventory(totalInventory)
				})
				.catch(error => {
					console.error('Error fetching inventory:', error)
					setInventory('Error')
				})
		}, [data.material_id])
	
		const style = inventory < 1 ? { color: 'red' } : { color: 'green' }
		return <span style={style}>{inventory !== null ? inventory : 'Loading...'}</span>
	}

	const shipmentColumns = [
		{ headerName: 'SKU', field: 'sku' },
		{ headerName: 'Material', field: 'name' },
		{ headerName: 'Ordered', field: 'total_quantity' },
		{ headerName: 'Delivered', field: 'shipped_quantity' },
		{ headerName: 'Outstanding', field: 'remaining_quantity' },
		{ headerName: 'Inventory', field: 'material', valueGetter: params => 0, cellRenderer: InventoryRenderer },
		{
			headerName: 'Quantity to Ship (Edit)',
			field: 'quantity_to_ship',
			editable: true,
			cellEditor: 'agNumberCellEditor',
			cellClassRules: {
				'quantity-over': params => params.value > params.data.remaining_quantity,
			},
			cellStyle: {color: '', 'background-color': 'rgba(204, 255, 204, 0.5)'}
		},
		{ headerName: 'Unit Rate', field: 'unit_rate', valueFormatter: params => `£${Number(params.value).toFixed(2)}` },
		{ headerName: 'Total Amount', field: 'total_amount', valueFormatter: params => `£${Number(params.value).toFixed(2)}` },
	]

	return (
		<div id='CreateShipment'>
			<PageTitle title={`Create Shipment for Job #J-${job_id}`}>
				<button className='shipment-save-button' type='button' onClick={handleSaveShipment} disabled={isSaving} style={{ float: 'right' }}>
					{isSaving ? 'Saving...' : 'Save Shipment'}
				</button>
			</PageTitle>
			<PageContent>
				{errorMessage && <ErrorMessage message={errorMessage} />}
				<form className='shipment-form'>
					<label>
						Shipment Address:
						<input type='text' name='shipment_address' value={shipmentInfo.shipment_address} onChange={handleInputChange} />
					</label>
					<label>
						Carrier:
						<input type='text' name='carrier' value={shipmentInfo.carrier} onChange={handleInputChange} />
					</label>
					<label>
						Tracking Code:
						<input type='text' name='tracking_code' value={shipmentInfo.tracking_code} onChange={handleInputChange} />
					</label>
					<label>
						Pallet Count:
						<input type='number' name='pallet_count' value={shipmentInfo.pallet_count} onChange={handleInputChange} />
					</label>
					<label>
						Parcel Count:
						<input type='number' name='parcel_count' value={shipmentInfo.parcel_count} onChange={handleInputChange} />
					</label>
					<label>
						Shipped Date:
						<input type='date' name='shipped_date' value={shipmentInfo.shipped_date} onChange={handleInputChange} />
					</label>
					<label>
						Delivery Date:
						<input type='date' name='delivery_date' value={shipmentInfo.delivery_date} onChange={handleInputChange} />
					</label>
					<label>
						Notes:
						<textarea name='notes' value={shipmentInfo.notes} onChange={handleInputChange} />
					</label>
				</form>
				<ButtonBar>
					<Button onClick={handleAutoFillQuantities}>Auto Fill Quantities</Button>
				</ButtonBar>
				<div className='ag-theme-quartz' style={{ height: 400, marginTop: 20 }}>
					<AgGridReact
						rowData={materials}
						columnDefs={shipmentColumns}
						pagination={false}
						rowSelection='single'
						onCellValueChanged={params => handleQuantityChange(params.data.material_id, params.newValue)}
					/>
				</div>
			</PageContent>
		</div>
	)
}

export default CreateShipment

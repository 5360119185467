import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PageContent from '../../layout/PageContent';
import PageTitle from '../../layout/PageTitle';
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage';
import generateDeliveryNote from '../../../utils/generateDeliveryNote';
import './Shipment.css';

const Shipment = () => {
  const { shipment_id } = useParams();
  const [shipmentDetails, setShipmentDetails] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios.get(`/api/shipment/${shipment_id}`)
      .then(response => {
        setShipmentDetails(response.data);
        return axios.get(`/api/job/${response.data.job_id}`);
      })
      .then(response => {
        setJobDetails(response.data);
      })
      .catch(error => {
        console.error('Error fetching shipment or job details:', error);
        setErrorMessage('Error fetching shipment or job details. Please try again.');
      });
  }, [shipment_id]);


  if (errorMessage) {
    return <ErrorMessage message={errorMessage} />;
  }

  if (!shipmentDetails || !jobDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div id='Shipment'>
      <PageTitle title={`Shipment #S-${shipment_id}`}>

      </PageTitle>
      <PageContent>
        <div className='shipment-details'>
          <p><strong>Job ID:</strong> J-{shipmentDetails.job_id}</p>
          <p><strong>Customer:</strong> {jobDetails.customer.display_name}</p>
          <p><strong>PO Number:</strong> {jobDetails.po_number}</p>
          <p><strong>Shipment Address:</strong> {shipmentDetails.shipment_address}</p>
          <p><strong>Carrier:</strong> {shipmentDetails.carrier}</p>
          <p><strong>Tracking Code:</strong> {shipmentDetails.tracking_code}</p>
          <p><strong>Pallet Count:</strong> {shipmentDetails.pallet_count}</p>
          <p><strong>Parcel Count:</strong> {shipmentDetails.parcel_count}</p>
          <p><strong>Shipped Date:</strong> {shipmentDetails.shipped_date?.split('T')[0]}</p>
          <p><strong>Delivery Date:</strong> {shipmentDetails.delivery_date?.split('T')[0]}</p>
          <p><strong>Notes:</strong> {shipmentDetails.notes}</p>
        </div>
        <div className='shipment-materials'>
          <h3>Materials</h3>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Material</th>
                <th>SKU</th>
                <th>Quantity Shipped</th>
              </tr>
            </thead>
            <tbody>
              {shipmentDetails.materials.map((material, index) => (
                <tr key={material.material_id}>
                  <td>{index + 1}</td>
                  <td>[{material.material_id}]{material.material_name}</td>
                  <td>{material.material.sku}</td>
                  <td>{parseFloat(material.quantity)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {shipmentDetails.is_void && <div className='void-notice' style={{color: "red", fontWeight: "bold", textAlign: "center", fontSize: "2em"}}>This shipment has been voided.</div>}
        </div>
        <div className='shipment-pdf-link'>
          {generateDeliveryNote(shipment_id, jobDetails, shipmentDetails, shipmentDetails.materials)}
        </div>
        <footer className='shipment-footer'>
        <p>Note: Shipments cannot be deleted or changed to maintain data integrity. If there is an issue with this shipment, please void it and generate a new delivery note.</p>
      </footer>
      </PageContent>
    </div>
  );
};

export default Shipment;

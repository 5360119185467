import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import './Materials.css'
import useFetch from '../../../hooks/useFetch'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useState, useMemo } from 'react'
import ButtonBar from '../../layout/ButtonBar/ButtonBar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import placeholderImage from '../../../images/placeholder-square.jpg'

const Materials = () => {
	const navigate = useNavigate()

	const { error, isPending, data: materials, reloadData } = useFetch('/api/material', {include_inventory: true})

	// Toggle to display NewMaterialForm
	const [showNewMaterialForm, setShowNewMaterialForm] = useState(false)
	const toggleNewMaterialForm = () => {
		setShowNewMaterialForm(!showNewMaterialForm)
	}

	// Retrieve the selected rows from the grid
	const [selectedRows, setSelectedRows] = useState([])
	const oneSelected = selectedRows.length === 1
	const selectedHasDefaultBomId = selectedRows[0]?.default_bom_id != null

	// Navigate to the Quick Assemble page
	const handleQuickAssembleClick = () => {
		navigate('/quick-assemble/' + selectedRows[0].material_id)
	}

	return (
		<div id='Materials'>
			<PageTitle title={"Materials"}></PageTitle>
			<PageContent>
				<ButtonBar>
					<button className='Button' onClick={toggleNewMaterialForm}>
						New Material
					</button>
					<button className='Button' onClick={handleQuickAssembleClick} disabled={!oneSelected || !selectedHasDefaultBomId}>
						Quick Assemble
					</button>
				</ButtonBar>
				{showNewMaterialForm && <NewMaterialForm reloadTable={reloadData} />}
				{error && <ErrorMessage message={error} />}
				<MaterialTable materials={materials} setSelectedRows={setSelectedRows} isLoading={isPending} />
			</PageContent>
		</div>
	)
}

const MaterialTable = ({ materials, setSelectedRows, isLoading }) => {
	const navigate = useNavigate()

	// Row Data: The data to be displayed.
	const rowData = materials
	const [gridApi, setGridApi] = useState(null)
	const [searchTerms, setSearchTerms] = useState('')

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	const gridOptions = {
		rowHeight: 60,
	}

	// Apply settings across all columns
	const defaultColDef = useMemo(() => ({
		filter: true,
	}))

	const handleRowClicked = e => {
		const material_id = e.data.material_id
		navigate('/material/' + material_id)
	}

	const imageRenderer = ({ value }) => {
		const hostLocation = 'https://storage.googleapis.com/conflux-uploads/'
		const materialImage = value ? hostLocation + value : placeholderImage
		return <img style={{ objectFit: 'contain', margin: '5px' }} height={50} width={50} alt={value} src={materialImage} />
	}

	const nameRenderer = ({ value, data }) => {
		return (
			<div style={{ display: 'flex', gap: '1rem' }}>
				{imageRenderer({ value: data.image_location })}
				<span>{value}</span>
			</div>
		)
	}

	const inventoryRenderer = ({ value, data }) => {
		const style =
			parseInt(value.totalQtyOnHand) < 1 ? { color: "red" } : { color: "green" }
		return (
			<span style={style}>{value.totalQtyOnHand}</span>
		)
	}

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{
			headerName: 'SKU',
			field: 'sku',
			checkboxSelection: true,
			headerCheckboxSelection: true,
		},
		{ headerName: 'Material', field: 'name', cellRenderer: nameRenderer },
		{ headerName: 'Inventory', field: 'inventory', cellRenderer: inventoryRenderer },
		{ headerName: 'Category', field: 'category' },
		{
			headerName: 'Vendable',
			field: 'is_vendable',
			editable: false,
			cellRendererParams: { disabled: true },
		},
	])

	const onSelectionChanged = () => {
		const selectedData = gridApi.getSelectedRows()
		setSelectedRows(selectedData)
	}

	// Sizing settings for the grid
	const autoSizeStrategy = {
		type: 'fitGridWidth',
		defaultMinWidth: 150,
		columnLimits: [
			{ colId: 'sku', minWidth: 200, maxWidth: 200 },
			{ colId: 'name', minWidth: 500 },
			{ colId: 'inventory', minWidth: 120 },
			{ colId: 'category', minWidth: 250, maxWidth: 300 },
			{ colId: 'is_vendable', minWidth: 150, maxWidth: 150 },
		],
	}

	const rowSelection = 'multiple'

	return (
		<div className='ag-theme-quartz' style={{ height: 600 }}>
			<input type='text' value={searchTerms} onChange={e => setSearchTerms(e.target.value)} placeholder='Search..' />
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				pagination={true}
				defaultColDef={defaultColDef}
				rowSelection={rowSelection}
				onSelectionChanged={onSelectionChanged}
				onGridReady={onGridReady}
				onRowClicked={handleRowClicked}
				autoSizeStrategy={autoSizeStrategy}
				quickFilterText={searchTerms}
				gridOptions={gridOptions}
			/>
		</div>
	)
}

const NewMaterialForm = ({ reloadTable }) => {
	const [inputs, setInputs] = useState({})

	const handleChange = event => {
		const name = event.target.name
		const value = event.target.value
		setInputs(values => ({ ...values, [name]: value }))
	}

	const handleSubmit = async event => {
		event.preventDefault()
		axios
			.post('/api/material', inputs)
			.then(res => {
				setInputs({})
				reloadTable()
			})
			.catch(err => {
				console.log(err)
				alert(err.response.data)
			})
	}
	return (
		<div id='NewMaterialForm'>
			<form onSubmit={handleSubmit}>
				<label htmlFor='name'>SKU</label>
				<input name='sku' type='text' value={inputs.sku || ''} onChange={handleChange} />
				<label htmlFor='name'>Name</label>
				<input name='name' type='text' value={inputs.name || ''} onChange={handleChange} />
				<label htmlFor='email'>Description</label>
				<input name='description' type='text' value={inputs.description || ''} onChange={handleChange} />
				<input type='submit' />
			</form>
		</div>
	)
}

export default Materials

import "./Inventory.css"
import ErrorMessage from "../../../layout/ErrorMessage/ErrorMessage"
import useFetch from "../../../../hooks/useFetch"
import LoadingMessage from "../../../layout/LoadingMessage/LoadingMessage"
import { useEffect, useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const Inventory = ({ materialId }) => {
	const {
		error,
		isPending,
		data: inventory,
		reloadData,
	} = useFetch("/api/inventory?material_id=" + materialId)

	const navigate = useNavigate()

	return (
		<div id='Inventory'>
			<button onClick={() => navigate(`/inventory-log/${materialId}`)}>Inventory Log</button>
			{error && <ErrorMessage message={error} />}
			{isPending && <LoadingMessage />}
			{inventory && (
				<InventoryEditor
					materialId={materialId}
					inventory={inventory}
					reloadData={reloadData}
				/>
			)}
		</div>
	)
}

const InventoryCreator = ({ materialId, inventory, reloadInventory }) => {
	// get a list of possible locations
	const {
		error,
		isPending,
		data: locations,
		reloadData: reloadLocations,
	} = useFetch("/api/location")

	// find all the locations which are not tracking inventory
	let untrackedLocations = {}
	if (inventory && locations)
		untrackedLocations = locations.filter(
			o1 => !inventory.some(o2 => o1.location_id === o2.location_id)
		)

	const handleClick = location_id => {
		axios
			.post("/api/inventory", {
				location_id: location_id,
				material_id: materialId,
				qty: 0,
			})
			.then(res => {
				console.log(res)
				reloadInventory()
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}

	return (
		<div id='InventoryCreator'>
			{error && <ErrorMessage message={error} />}
			{isPending && <LoadingMessage />}
			{untrackedLocations.length > 0 && (
				<InventoryCreatorSelector
					untrackedLocations={untrackedLocations}
					handleClick={handleClick}
				/>
			)}
		</div>
	)
}

const InventoryCreatorSelector = ({ untrackedLocations, handleClick }) => {
	return untrackedLocations.map(location => {
		return (
			<div
				className='InventoryCreatorSelector'
				key={location.location_id}>
				<span>{location.name} (not tracked) </span><br/>
				<button onClick={() => handleClick(location.location_id)}>
					Track Inventory
				</button>
			</div>
		)
	})
}

const InventoryEditor = ({ materialId, inventory, reloadData }) => {
	return (
		<div id='InventoryEditor'>
			{inventory.length < 1 && (
				<div>
					<p>Inventory is not tracked at any locations.</p>
				</div>
			)}
			{inventory.length > 0 &&
				inventory.map(l => {
					return (
						<InventoryLine
							key={l.inventory_id}
							line={l}
							reloadData={reloadData}
						/>
					)
				})}
			{inventory && (
				<InventoryCreator
					materialId={materialId}
					inventory={inventory}
					reloadInventory={reloadData}
				/>
			)}
		</div>
	)
}

const InventoryLine = ({ line, reloadData }) => {
	const qty = parseInt(line.qty_on_hand)
	const defaultState = { operation: "set", value: qty, reason: "Adjustment" }
	const [inputs, setInputs] = useState(defaultState)

	useEffect(() => {
		setInputs(defaultState)
	}, [qty])

	const change = parseInt(inputs.value)
	let result = parseInt(line.qty_on_hand)
	if (inputs.operation && !isNaN(change)) {
		switch (inputs.operation) {
			case "add":
				result = qty + change
				break
			case "minus":
				result = qty - change
				break
			case "set":
				result = change
				break
			default:
				result = qty
		}
	}

	const diff = result - line.qty_on_hand
	const diffStr = (diff < 0 ? "" : "+") + diff
	const shouldDisplayChange = diff !== 0

	const handleQtyChange = event => {
		const value = event.target.value
		setInputs({ ...inputs, value: value })
	}

	const handleReasonInputChange = event => {
		const value = event.target.value
		setInputs({ ...inputs, reason: value })
	}

	const handleOpButton = (e, operation) => {
		e.preventDefault()
		let newValue = qty
		if (operation === "add" || operation === "minus") newValue = 0
		setInputs({ ...inputs, operation: operation, value: newValue })
	}

	const handleStepButton = (e, value) => {
		e.preventDefault()
		const newValue = inputs.value - value
		setInputs({ ...inputs, value: newValue })
	}

	const handleSubmit = async event => {
		event.preventDefault()
		axios
			.put("/api/inventory", {
				location_id: line.location_id,
				material_id: line.material_id,
				operation: "add",
				value: diff,
				reason: inputs.reason,
			})
			.then(res => {
				reloadData()
			})
			.catch(err => {
				alert(err.message)
			})
	}

	return (
		<form className='InventoryLine' onSubmit={handleSubmit}>
			<p>
				{line.location_name} - On hand:{" "}
				<span className='inv-qty'>{qty}</span>
			</p>
			<div className='inv-input-group'>
				<button
					onClick={e => handleOpButton(e, "add")}
					className={inputs.operation === "add" && "selected"}>
					<span>Add</span>
				</button>
				<button
					onClick={e => handleOpButton(e, "minus")}
					className={inputs.operation === "minus" && "selected"}>
					<span>Minus</span>
				</button>
				<button
					onClick={e => handleOpButton(e, "set")}
					className={inputs.operation === "set" && "selected"}>
					<span>Set</span>
				</button>
				<input
					type='number'
					value={inputs.value}
					onChange={handleQtyChange}
				/>
				<div className='step-buttons'>
					<button
						type='button'
						onClick={e => handleStepButton(e, -1)}>
						<svg
							viewBox='0 0 16 16'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							role='presentation'
							height='1rem'
							width='1rem'
							color='#37424D'>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M4.91021 10.47L7.97021 7.4167L11.0302 10.47L11.9702 9.53003L7.97021 5.53003L3.97021 9.53003L4.91021 10.47Z'
								fill='currentColor'></path>
						</svg>
					</button>
					<button type='button' onClick={e => handleStepButton(e, 1)}>
						<svg
							viewBox='0 0 16 16'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							role='presentation'
							height='1rem'
							width='1rem'
							color='#37424D'>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M11.0302 5.52997L7.97021 8.5833L4.91021 5.52997L3.97021 6.46997L7.97021 10.47L11.9702 6.46997L11.0302 5.52997Z'
								fill='currentColor'></path>
						</svg>
					</button>
				</div>
			</div>
			{shouldDisplayChange && (
				<p className='inv-change'>
					<span className='inv-s'>{qty}</span>{" "}
					<span
						className={
							"inv-d " + (diff < 0 ? "inv-minus" : "inv-plus")
						}>
						{diffStr}
					</span>{" "}
					= <span className='inv-r'>{result}</span>
				</p>
			)}
			<label>Reason</label>
			<select
				name='reason'
				value={inputs.reason}
				onChange={handleReasonInputChange}>
				<option value='Adjustment'>Adjustment</option>
				<option value='Received'>Received</option>
				<option value='Sold / Shipped'>Sold / Shipped</option>
				<option value='Free Issue'>Free Issue</option>
				<option value='Replacement'>Replacement</option>
				<option value='Damaged'>Damaged</option>
				<option value='Missing'>Missing</option>
				<option value='Found'>Found</option>
			</select>
			<div className='buttonContainer full-width'>
				<input
					type='submit'
					value='Save'
					disabled={!shouldDisplayChange}
				/>
			</div>
		</form>
	)
}

export default Inventory

import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    padding: 20,
    flexDirection: 'column',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  logo: {
    width: 50,
    height: 20,
  },
  companyInfoText: {
    fontSize: 8,
    textAlign: 'right',
  },
  title: {
    fontSize: 14,
    marginBottom: 8,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  topSection: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  topColumn: {
    flex: 1,
    paddingRight: 4,
  },
  topRow: {
    flexDirection: 'row',
    marginBottom: 4,
  },
  topLabel: {
    width: 80,
    fontWeight: 'bold',
  },
  topValue: {
    flex: 1,
  },
  addressBlock: {
    marginBottom: 8,
    padding: 6,
    backgroundColor: '#f5f5f5',
  },
  addressTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  addressText: {
    fontSize: 10,
  },
  materialsTable: {
    display: 'table',
    width: '100%',
    marginBottom: 8,
  },
  materialsHeaderRow: {
    flexDirection: 'row',
    backgroundColor: '#eaeaea',
  },
  materialRow: {
    flexDirection: 'row',
  },
  materialCol: {
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#bfbfbf',
    padding: 3,
    fontSize: 9,
  },
  colIndex: { flex: 0.5 },
  colMaterial: { flex: 2 },
  colSKU: { flex: 1 },
  colQuantity: { flex: 1 },
  colUnitRate: { flex: 1 },
  colTotalAmount: { flex: 1 },
  signatureSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
    marginBottom: 12,
  },
  signatureBox: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: '#000',
    marginRight: 10,
    paddingBottom: 4,
  },
  dateBox: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: '#000',
    marginLeft: 10,
    paddingBottom: 4,
  },
  signatureLabel: {
    fontSize: 9,
    marginBottom: 2,
  },
  footer: {
    textAlign: 'center',
    marginTop: 8,
    fontSize: 8,
  },
  voidNotice: {
    color: 'red',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(-45deg)',
    zIndex: 1,
    opacity: 0.5,
    border: '5px solid red',
    borderRadius: 10,
    padding: 20,
  },
});

const DeliveryNote = ({ shipment_id, jobDetails, shipmentDetails, materials }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {shipmentDetails.is_void && (
        <Text style={styles.voidNotice}>VOID</Text>
      )}
      {/* Header */}
      <View style={styles.header}>
        <Image style={styles.logo} src="data:image/jpeg;base64,..." />
        <Text style={styles.companyInfoText}>
          Etree Productions Limited | 1 Brewers Hill Rd, Dunstable, Bedfordshire, LU6 1AA | Phone: +(44)1582 235 148 | Email: accounts@etr.ee
        </Text>
      </View>

      {/* Title */}
      <Text style={styles.title}>Delivery Note</Text>

      {/* Top Section with two side-by-side borderless tables */}
      <View style={styles.topSection}>
        {/* Left Table */}
        <View style={styles.topColumn}>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Shipment ID:</Text>
            <Text style={styles.topValue}>{`#S-${shipment_id}`}</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Job ID:</Text>
            <Text style={styles.topValue}>{`#J-${shipmentDetails?.job_id}`}</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Customer:</Text>
            <Text style={styles.topValue}>{jobDetails?.customer.company_name}</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Customer #:</Text>
            <Text style={styles.topValue}>{jobDetails?.customer.customer_number}</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>PO Number:</Text>
            <Text style={styles.topValue}>{jobDetails?.po_number}</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Notes:</Text>
            <Text style={styles.topValue}>{shipmentDetails?.notes}</Text>
          </View>
        </View>
        {/* Right Table */}
        <View style={styles.topColumn}>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Carrier:</Text>
            <Text style={styles.topValue}>{shipmentDetails?.carrier}</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Tracking:</Text>
            <Text style={styles.topValue}>{shipmentDetails?.tracking_code}</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Pallets:</Text>
            <Text style={styles.topValue}>{shipmentDetails?.pallet_count}</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Parcels:</Text>
            <Text style={styles.topValue}>{shipmentDetails?.parcel_count}</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Shipped:</Text>
            <Text style={styles.topValue}>{shipmentDetails?.shipped_date?.split('T')[0]}</Text>
          </View>
          <View style={styles.topRow}>
            <Text style={styles.topLabel}>Delivery:</Text>
            <Text style={styles.topValue}>{shipmentDetails?.delivery_date?.split('T')[0]}</Text>
          </View>
        </View>
      </View>

      {/* Enlarged Delivery Address */}
      <View style={styles.addressBlock}>
        <Text style={styles.addressTitle}>Delivery Address</Text>
        <Text style={styles.addressText}>{shipmentDetails?.shipment_address}</Text>
      </View>

      {/* Materials Table */}
      <View style={styles.materialsTable}>
        <View style={styles.materialsHeaderRow}>
          <View style={[styles.materialCol, styles.colIndex]}>
            <Text>#</Text>
          </View>
          <View style={[styles.materialCol, styles.colMaterial]}>
            <Text>Material</Text>
          </View>
          <View style={[styles.materialCol, styles.colSKU]}>
            <Text>SKU</Text>
          </View>
          <View style={[styles.materialCol, styles.colQuantity]}>
            <Text>Qty Shipped</Text>
          </View>
        </View>
        {materials.map((material, index) => (
          <View style={styles.materialRow} key={index}>
            <View style={[styles.materialCol, styles.colIndex]}>
              <Text>{index + 1}</Text>
            </View>
            <View style={[styles.materialCol, styles.colMaterial]}>
              <Text>{material.material_name}</Text>
            </View>
            <View style={[styles.materialCol, styles.colSKU]}>
              <Text>{material.material.sku}</Text>
            </View>
            <View style={[styles.materialCol, styles.colQuantity]}>
              <Text>{parseFloat(material.quantity)}</Text>
            </View>
          </View>
        ))}
      </View>

      {/* Signature Section */}
      <Text>I acknowledge receipt of the items listed above in good condition. Any discrepancies, faults, defects, or damages must be reported within 30 days of receipt.</Text>
      <View style={styles.signatureSection}>
        <View style={styles.signatureBox}>
          <Text style={styles.signatureLabel}>Signature:</Text>
        </View>
        <View style={styles.dateBox}>
          <Text style={styles.signatureLabel}>Date:</Text>
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Thank you for your business!</Text>
        <Text>If you have any questions about this delivery note, please contact us.</Text>
      </View>
    </Page>
  </Document>
);

export default DeliveryNote;

import { useState } from 'react'
import axios from 'axios'
import ErrorMessage from '../layout/ErrorMessage/ErrorMessage'
import LoadingMessage from '../layout/LoadingMessage/LoadingMessage'
import './UploadImage.css'

const UploadImage = ({ setImageLocation }) => {
	const [file, setFile] = useState()
	const [errorMessage, setErrorMessage] = useState('')
	const [isUploading, setIsUploading] = useState(false)

	const submit = async event => {
		event.preventDefault()
		setIsUploading(true)
		setErrorMessage('')

		const formData = new FormData()
		formData.append('image', file)

		axios
			.post('/api/image', formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(res => {
				setIsUploading(false)
				if (res?.data?.location) {
					setImageLocation(res.data.location)
				}
			})
			.catch(err => {
				console.log(err)
				setIsUploading(false)
				setErrorMessage(err.response.data?.error || err.message)
			})
	}

	return (
		<div className='ImageUpload'>
			{errorMessage && <ErrorMessage message={errorMessage} />}
			{isUploading && <LoadingMessage message={'Uploading image...'} />}
			<form onSubmit={submit}>
				<input
					filename={file}
					onChange={e => setFile(e.target.files[0])}
					type='file'
					accept='image/*'
					className='file-input'
				/>
				<button className="Button" type='submit'>Upload</button>
			</form>
		</div>
	)
}

export default UploadImage

import "./ButtonBar.css"

function ButtonBar(props) {
	return <div className='ButtonBar'>{props.children}</div>
}

function Button({ link, text, onClick, disabled = false, children, className }) {
	return (
		<a className={`Button ${disabled ? 'disabled' : ''} ${className && className}`} href={link} onClick={disabled ? null : onClick} disabled={disabled}>
			{text || children}
		</a>
	)
}

export default ButtonBar
export { ButtonBar, Button }

import "./Assemble.css"
import useFetch from "../../../../hooks/useFetch"
import ErrorMessage from "../../../layout/ErrorMessage/ErrorMessage"
import LoadingMessage from "../../../layout/LoadingMessage/LoadingMessage"
import { useCallback, useContext, useMemo, useState } from "react"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { AgGridReact } from "ag-grid-react"
import UserContext from "../../../Context/UserContext"
import "./Assemble.css"
import { Link } from "react-router-dom"
import { formatCurrency } from "../../../../utils/format"

const Assemble = ({ materialId, bomId, material }) => {
	const { user } = useContext(UserContext)
	console.log(user)
	const locationId = user.activeLocation.id
	const locationName = user.activeLocation.name

	const {
		error,
		isPending,
		data: bom,
		reloadData,
	} = useFetch("/api/bom", {
		bom_id: bomId,
		location_id: locationId,
	})

	if (!materialId) return <p>No material selected.</p>
	if (!bomId) return <p>No BOM selected.</p>

	return (
		<div id='Assemble'>
			<h3>Assemble (at {locationName})</h3>
			{error && <ErrorMessage message={error} />}
			{isPending && <LoadingMessage />}
			{bom && (
				<AssembleController
					bom={bom}
					material={material}
					locationId={locationId}
					reloadData={reloadData}
				/>
			)}
		</div>
	)
}

const AssembleController = ({ bom, material, locationId, reloadData }) => {
	const [numberToAssemble, setNumberToAssemble] = useState(0)

	console.log("bom", bom)

	const handleQtyInputChange = e => {
		e.preventDefault()
		setNumberToAssemble(e.target.value)
	}

	const handleAssemble = () => {
		if (numberToAssemble === 0) return alert("Enter a number to assemble")
		axios
			.post("/api/assemble", {
				locationId: 1,
				materialId: material.material_id,
				bomId: bom.bom_id,
				qtyToAssemble: numberToAssemble,
			})
			.then(res => {
				setNumberToAssemble(0)
				reloadData()
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}

	const totalCost = Object.values(bom.materials).reduce((t, r) => {
		const unitCost = parseFloat(r.material.cost_price || 0)
		const unitQty = parseInt(r.qty)
		const totalCost = unitCost * unitQty * parseInt(numberToAssemble)
		return t + totalCost
	}, 0)

	const totalRevenue = numberToAssemble * material.sales_price

	const buildDataRow = r => {
		const availableInventory = parseFloat(r.inventory?.qty_on_hand) || 0
		const unitCost = formatCurrency(
			r.material.cost_price ? parseFloat(r.material.cost_price) : 0
		)
		const inventoryChange =
			-1 * parseFloat(r.qty) * parseFloat(numberToAssemble)
		const totalUnitCost = formatCurrency(
			r.material.cost_price
				? -1 *
						parseFloat(r.material.cost_price) *
						parseFloat(inventoryChange)
				: 0
		)

		return {
			materialId: r.material_id,
			materialSku: r.material.sku,
			materialName: r.material.name,
			startingQty: r.qty,
			availableInventory: availableInventory + inventoryChange,
			inventoryChange: inventoryChange,
			unitCost: unitCost,
			totalCost: totalUnitCost,
		}
	}

	const data = [...bom.materials.map(r => buildDataRow(r))]

	return (
		<div>
			<p>
				Assembling{" "}
				<b>
					{material.name} [{material.sku}]
				</b>{" "}
				using bom{" "}
				<b>
					{bom.bom_name} [{bom.bom_id}]
				</b>
				.
			</p>
			<label>Enter number to assemble: </label>
			<input
				type='number'
				value={numberToAssemble}
				onChange={e => handleQtyInputChange(e)}
			/>
			<button
				className='Button'
				onClick={handleAssemble}
				disabled={
					!numberToAssemble || parseFloat(numberToAssemble) === 0
				}>
				Assemble
			</button>
			<AssembleTable data={data} />
			<p>Total Cost: {formatCurrency(totalCost)}</p>
			<p>Total Revenue: {formatCurrency(totalRevenue)}</p>
			<p>
				Total Profit: {formatCurrency(totalRevenue - totalCost)} (
				{(((totalRevenue - totalCost) / totalRevenue) * 100).toFixed(2)}
				%)
			</p>
		</div>
	)
}

const AssembleTable = ({ data }) => {
	// Row Data: The data to be displayed.
	const rowData = data
	const [gridApi, setGridApi] = useState(null)

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	// Apply settings across all columns
	const defaultColDef = useMemo(() => ({
		filter: false,
	}))

	const colorValuesRenderer = ({ value }) => {
		const style =
			parseInt(value) < 1 ? { color: "red" } : { color: "green" }
		return <span style={style}>{value}</span>
	}

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{
			headerName: "ID",
			field: "materialId",
			cellRenderer: function (params) {
				return (
					<Link to={"/material/" + params.value}>
						{params.value}{" "}
						<FontAwesomeIcon icon={faUpRightFromSquare} />
					</Link>
				)
			},
		},
		{ headerName: "SKU", field: "materialSku" },
		{ headerName: "Name", field: "materialName" },
		{
			headerName: "QTY",
			field: "startingQty",
			cellRenderer: colorValuesRenderer,
		},
		{
			headerName: "Total QTY",
			field: "inventoryChange",
			cellRenderer: colorValuesRenderer,
		},
		{
			headerName: "Current Inventory",
			field: "availableInventory",
			cellRenderer: colorValuesRenderer,
		},
		{ headerName: "Unit Cost", field: "unitCost" },
		{ headerName: "Total Cost", field: "totalCost" },
	])

	// Sizing settings for the grid
	const autoSizeStrategy = {
		type: "fitGridWidth",
		defaultMinWidth: 100,
		columnLimits: [
			{ colId: "materialName", minWidth: 200 },
			{ colId: "availableInventory", minWidth: 150 },
		],
	}

	return (
		<div className='ag-theme-quartz' style={{ height: 400 }}>
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				pagination={false}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				autoSizeStrategy={autoSizeStrategy}
			/>
		</div>
	)
}

export default Assemble

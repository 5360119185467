import UnitsAssembledMonthBarChart from './charts/UnitsAssembledMonthBarChart'
import TestChart from './charts/UnitsAssembledMonthBarChart'
import Collapsible from './layout/Collapsible/Collapsible'
import PageContent from './layout/PageContent'
import PageTitle from './layout/PageTitle'
import { Clocking } from './pages/Timesheets/Timesheets'

function Homepage() {
	return (
		<>
			<PageTitle title="Dashboard"></PageTitle>
			<PageContent>
				<div className='home-layout'>
					<div className='hl-left'>
						<Collapsible title={'Announcements'}>
							<section>
								<p>Jobs page under development.</p>
							</section>
						</Collapsible>
						<Collapsible title={'Assembly'}>
							<UnitsAssembledMonthBarChart />
						</Collapsible>
					</div>
					<div className='hl-right'>
						<Collapsible title={'Clocking'}>
							<Clocking />
						</Collapsible>
					</div>
				</div>
			</PageContent>
		</>
	)
}

export default Homepage

import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DeliveryNote from '../components/DeliveryNote';

const generateDeliveryNote = (shipment_id, jobDetails, shipmentDetails, materials) => {
  const shipmentDate = shipmentDetails.shipped_date?.split('T')[0];
  return (
    <PDFDownloadLink
      document={
        <DeliveryNote
          shipment_id={shipment_id}
          jobDetails={jobDetails}
          shipmentDetails={shipmentDetails}
          materials={materials}
        />
      }
      fileName={`Delivery_Note_for_S-${shipmentDetails.shipment_id}_${shipmentDate}.pdf`}
    >
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : 'Download Delivery Note'
      }
    </PDFDownloadLink>
  );
};

export default generateDeliveryNote;

import PageTitle from '../../layout/PageTitle'
import { AgGridReact } from 'ag-grid-react' // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css' // Optional Theme applied to the Data Grid
import { useMemo, useState, useCallback } from 'react'
import { ButtonBar } from '../../layout/ButtonBar/ButtonBar'
import PageContent from '../../layout/PageContent'
import useFetch from '../../../hooks/useFetch'
import axios from 'axios'
import './Customers.css'
import LoadingMessage from '../../layout/LoadingMessage/LoadingMessage'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'

const Customers = () => {
	const { error, isPending, data: customers, reloadData: reloadCustomerTable } = useFetch('/api/customer')

	// retrieve the selected rows from the grid
	const [selectedCustomers, setSelectedCustomers] = useState([])
	// boolean for if customers are selected
	const customersAreSelected = selectedCustomers.length > 0
	// handle when the delete selected customers button is pressed
	const handleArchiveSelectedCustomers = () => {
		// err here
		let customerIds = selectedCustomers.map(({ customer_id }) => customer_id)
		axios
			.post('/api/customer/archive', customerIds)
			.then(res => {
				console.log(res)
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}

	// toggle new customer form
	const [showNewCustomerForm, setShowNewCustomerForm] = useState(false)
	const toggleNewCustomerForm = () => {
		setShowNewCustomerForm(!showNewCustomerForm)
	}

	const [showEditCustomerForm, setShowEditCustomerForm] = useState(false)

	const toggleEditCustomerForm = customer => {
		setShowEditCustomerForm(!showEditCustomerForm)
	}

	const handleEditCustomer = async (customerId, updatedData) => {
		try {
			const response = await axios.put(`/api/customer/${customerId}`, updatedData)
			alert('Customer updated successfully.')
			reloadCustomerTable()
		} catch (err) {
			console.error(err)
			alert(err.message)
		}
	}

	const handleEditCustomerButtonClick = () => {
		if (selectedCustomers.length === 1) {
			toggleEditCustomerForm(selectedCustomers[0])
		} else {
			setShowEditCustomerForm(false)
		}
	}

	return (
		<div id='Customers'>
			<PageTitle title={'Customers'}></PageTitle>
			<PageContent>
				<ButtonBar>
					<button className='Button' onClick={toggleNewCustomerForm}>
						New Customer
					</button>
					<button className='Button' onClick={handleArchiveSelectedCustomers} disabled={!customersAreSelected}>
						Archive Customer(s)
					</button>
					<button className='Button' onClick={handleEditCustomerButtonClick} disabled={selectedCustomers.length !== 1}>
						Edit Customer
					</button>
				</ButtonBar>
				{error && <ErrorMessage message={error} />}
				{isPending && <LoadingMessage />}
				{showNewCustomerForm && <NewCustomerForm reloadCustomerTable={reloadCustomerTable} setShowNewCustomerForm={setShowNewCustomerForm} />}
				{customers && <CustomersTable data={customers} setSelectedCustomers={setSelectedCustomers} toggleEditCustomerForm={toggleEditCustomerForm} />}
				{showEditCustomerForm && selectedCustomers.length === 1 && <EditCustomerForm customer={selectedCustomers[0]} handleEditCustomer={handleEditCustomer} />}
			</PageContent>
		</div>
	)
}

const CustomersTable = props => {
	// Row Data: The data to be displayed.
	const rowData = props.data
	console.log(props.data)
	const [gridApi, setGridApi] = useState(null)

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	// Apply settings across all columns
	const defaultColDef = useMemo(() => ({
		filter: true,
	}))

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{ headerName: 'Customer #', field: 'customer_number', checkboxSelection: true, headerCheckboxSelection: true },
		{ headerName: 'Display Name', field: 'display_name' },
		{ headerName: 'Company Name', field: 'company_name' },
		{ headerName: 'Email', field: 'email' },
		{ headerName: 'Phone Number', field: 'phone_number' },
		{ headerName: 'Website', field: 'website' },
		{ headerName: 'VAT Number', field: 'vat_number' },
		{ headerName: 'Shipment Address', field: 'shipment_address' },
	])

	const onSelectionChanged = () => {
		const selectedData = gridApi.getSelectedRows()
		props.setSelectedCustomers(selectedData)
	}

	const rowSelection = 'multiple'

	const onRowDoubleClicked = event => {
		props.toggleEditCustomerForm(event.data)
	}

	return (
		<div className='ag-theme-quartz' style={{ height: 400 }}>
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				pagination={true}
				defaultColDef={defaultColDef}
				rowSelection={rowSelection}
				onSelectionChanged={onSelectionChanged}
				onGridReady={onGridReady}
				onRowDoubleClicked={onRowDoubleClicked}
			/>
		</div>
	)
}

const NewCustomerForm = props => {
	const [inputs, setInputs] = useState({})
	const [errors, setErrors] = useState([])

	const handleChange = event => {
		const name = event.target.name
		const value = event.target.value
		setInputs(values => ({ ...values, [name]: value }))
	}

	const handleSubmit = async event => {
		event.preventDefault()
		try {
			const response = await axios.post('/api/customer', inputs)
			alert('New customer created.')
			props.reloadCustomerTable()
			props.setShowNewCustomerForm(false)
		} catch (err) {
			if (err.response && err.response.data.errors) {
				setErrors(err.response.data.errors)
			} else {
				alert(err.message)
			}
		}
	}

	return (
		<div id='NewCustomerForm'>
			<form onSubmit={handleSubmit} className='customer-form'>
				{errors.length > 0 && (
					<div className='error-messages'>
						{errors.map((error, index) => (
							<p key={index}>{error}</p>
						))}
					</div>
				)}
				<div className='form-group'>
					<label htmlFor='display_name'>Display Name</label>
					<input name='display_name' type='text' value={inputs.display_name || ''} onChange={handleChange} />
				</div>
				<div className='form-group'>
					<label htmlFor='company_name'>Company Name</label>
					<input name='company_name' type='text' value={inputs.company_name || ''} onChange={handleChange} />
				</div>
				<div className='form-group full-width'>
					<input type='submit' value='Create Customer' />
				</div>
			</form>
		</div>
	)
}

const EditCustomerForm = ({ customer, handleEditCustomer }) => {
	const [inputs, setInputs] = useState(customer)
	const [errors, setErrors] = useState([])

	const handleChange = event => {
		const name = event.target.name
		const value = event.target.value
		setInputs(values => ({ ...values, [name]: value }))
	}

	const handleSubmit = async event => {
		event.preventDefault()
		try {
			await handleEditCustomer(customer.customer_id, inputs)
		} catch (err) {
			if (err.response && err.response.data.errors) {
				setErrors(err.response.data.errors)
			} else {
				alert(err.message)
			}
		}
	}

	return (
		<div id='EditCustomerForm'>
			<form onSubmit={handleSubmit} className='customer-form'>
				{errors.length > 0 && (
					<div className='error-messages'>
						{errors.map((error, index) => (
							<p key={index}>{error}</p>
						))}
					</div>
				)}
				<div className='form-group'>
					<label htmlFor='display_name'>Display Name</label>
					<input name='display_name' type='text' value={inputs.display_name || ''} onChange={handleChange} />
				</div>
				<div className='form-group'>
					<label htmlFor='company_name'>Company Name</label>
					<input name='company_name' type='text' value={inputs.company_name || ''} onChange={handleChange} />
				</div>
				<div className='form-group'>
					<label htmlFor='email'>Email</label>
					<input name='email' type='email' value={inputs.email || ''} onChange={handleChange} />
				</div>
				<div className='form-group'>
					<label htmlFor='phone_number'>Phone Number</label>
					<input name='phone_number' type='text' value={inputs.phone_number || ''} onChange={handleChange} />
				</div>
				<div className='form-group'>
					<label htmlFor='website'>Website</label>
					<input name='website' type='text' value={inputs.website || ''} onChange={handleChange} />
				</div>
				<div className='form-group'>
					<label htmlFor='vat_number'>VAT Number</label>
					<input name='vat_number' type='text' value={inputs.vat_number || ''} onChange={handleChange} />
				</div>
				<div className='form-group'>
					<label htmlFor='shipment_address'>Shipment Address</label>
					<input name='shipment_address' type='text' value={inputs.shipment_address || ''} onChange={handleChange} />
				</div>
				<div className='form-group'>
					<label htmlFor='customer_number'>Customer Number</label>
					<input name='customer_number' type='text' value={inputs.customer_number || ''} onChange={handleChange} />
				</div>
				<div className='form-group full-width'>
					<input type='submit' value='Update Customer' />
				</div>
			</form>
		</div>
	)
}

export default Customers
